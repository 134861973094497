import React, { useEffect, useState } from 'react';
import {
    Stack,
    IImageProps,
    ImageFit,
    SearchBox,
    IIconProps,
    Callout,
    mergeStyleSets,
    FontWeights,
    DefaultButton,
    CommandButton,
    IContextualMenuProps,
    IStackItemStyles
} from '@fluentui/react';
import Filter_ico from '../icons/Filter.svg';
import '../styles/Topbar.css';
import { useBoolean, useId } from '@fluentui/react-hooks';
import Filters from './Filters';
import { useTranslation } from 'react-i18next';
import up_ico from '../icons/up.svg';
import down_ico from '../icons/down.svg';

const imagePropss: IImageProps = {
    src: Filter_ico,
    imageFit: ImageFit.center,
    width: 17,
    height: 17
};
const styles = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 320,
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    link: {
        display: 'block',
        marginTop: 20,
    },
});
const goBackButton: IStackItemStyles = {
    root: {
        width: "fit-content",
        margin: 12
    }
}

const addIcon: IIconProps = {
    imageProps: imagePropss
};
const upIcon: IIconProps = { imageProps: {src: up_ico, imageFit: ImageFit.center, width: 20, height: 20} };
const downIcon: IIconProps = { imageProps: {src: down_ico, imageFit: ImageFit.center, width: 20, height: 20} };
function Topbar(props: any) {
    const { t, i18n } = useTranslation();
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const [sort, setSort] = useState<string>('DESCENDING');
    const [searchString, setSearchString] = useState<string>('');

    const setDesc = () => {
        setSort(sort => (sort = 'DESCENDING'))
        _query();
    }
    const setAsc = () => {
        setSort(sort => (sort = 'ASCENDING'))
        _query();
    }
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'ASCENDING',
                text: t('DocumentsSort.sortAsc'),
                canCheck: true,
                onClick: setAsc
            },
            {
                key: 'DESCENDING',
                text: t('DocumentsSort.sortDesc'),
                canCheck: true,
                onClick: setDesc

            },
        ],
    };

    const _query = (query?: any) => {

        if (query === undefined) {
            query = {
                'sort': sort,
                'freeTextSearch': searchString
            }
        } else {
            query.sort = sort;
            query.searchString = searchString;
        }
        props.setQuery(query)
        props.setPaginateStep(22)
    }
    const onChangeSearch = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | any): void => {
        setSearchString(newValue || '');
    };
    useEffect(() => {
        _query();
        // documentView
    }, [searchString, sort])

    function goback (){
        window.history.back()
    }

    return (
        <Stack horizontal className='topbar-container' wrap>
            {(/SharePoint/i).test(window.location.href) && <Stack.Item><DefaultButton styles={goBackButton} onClick={goback}>{t('TopBar.GoBack')}</DefaultButton></Stack.Item>}
            {props.documentView ?
                <Stack.Item grow={30}>
                    <DefaultButton id='back'
                        onClick={props.back}
                        text='< Back'
                        className='back-button'
                    />
                </Stack.Item> :
                <Stack.Item grow><h3>{t('TopBar.Received')}</h3></Stack.Item>
            }
            <Stack.Item grow={1}>
                <Stack horizontal className='topbar-search-container'>
                    <Stack.Item>
                        <CommandButton iconProps={addIcon} text={t('DocumentsSort.sort')} menuProps={menuProps} className='topbar-sort-button' />
                    </Stack.Item>
                    <Stack.Item>
                        <SearchBox placeholder="Search"
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={onChangeSearch}
                        />

                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            id={buttonId}
                            onClick={toggleIsCalloutVisible}
                            iconProps={isCalloutVisible ? upIcon : downIcon}
                            className='advanced-search-button'
                        />
                    </Stack.Item>
                    {isCalloutVisible && (
                        <Callout
                            className={styles.callout}
                            ariaLabelledBy={labelId}
                            ariaDescribedBy={descriptionId}
                            role="alertdialog"
                            gapSpace={0}
                            target={`#${buttonId}`}
                            onDismiss={toggleIsCalloutVisible}
                            setInitialFocus
                        >

                            <Filters query={_query}></Filters>
                        </Callout>
                    )}
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

export default Topbar
