import React, {useEffect, useState} from 'react';
import {DefaultButton, IStackStyles, ITextFieldStyles, Stack, TextField} from '@fluentui/react';
import {useTranslation} from "react-i18next";
import {Base64} from "js-base64";

const narrowTextFieldStyles: Partial<ITextFieldStyles> = {fieldGroup: {width: 100}};
const stackStyles: IStackStyles = {
    root: {
        height: '100%',
        minHeight: '100%',
        margin: 16,
        alignSelf: "center",
        background: "initial"
    },
};
const wrapperStyles: IStackStyles = {
    root: {
        maxWidth: 400
    },
};
const alignStyles: IStackStyles = {
    root: {
        textAlign: "center"
    },
};
const marginStyles: IStackStyles = {
    root: {
        margin: 16
    },
};
export default function OtpHelper(props: any) {
    const {t, i18n} = useTranslation();
    const [firstChallengeToResponse, setFirstChallengeToResponse] = useState<string>('');
    const [thirdChallengeToResponse, setThirdChallengeToResponse] = useState<string>('');
    // const [secondChallengeToResponse, setSecondChallengeToResponse] = useState<string>();
    const [tempChallengeDataBeforeDecision, setTempChallengeDataBeforeDecision] = useState<any>();
    const [tempChallengeDataAfterDecision, setTempChallengeDataAfterDecision] = useState<any>();
    const [layoutAttributes, setLayoutAttributes] = useState<any>();
    const [codeInput, setCodeInput] = useState<boolean>(false);
    const [secondTextFieldValue, setSecondTextFieldValue] = useState('');
    const [maskedPhoneNumber, setMaskedPhoneNumber] = useState();
    const [organizationName, setOrganizationName] = useState();

    function getAction() {
        let tempChallenge: any = [];
        props.otpChallange.map((challenge: any, i: any) => {
            if (challenge.attributes.assertions) {
                if (props.step === 1) {
                    setFirstChallengeToResponse(challenge.attributes.assertions);
                    localStorage.setItem(`first-${props.documentId}`, challenge.attributes.assertions)
                } else {
                    setThirdChallengeToResponse(challenge.attributes.assertions)
                }

            }
            if (challenge.attributes.options) {
                tempChallenge['classifiers'] = challenge.classifiers;
                if (props.step === 1) {
                    setTempChallengeDataBeforeDecision(challenge);
                }
            }
            if (challenge.attributes.organizationName) {
                setTempChallengeDataAfterDecision(challenge);
                setOrganizationName(challenge.attributes.organizationName);
                setMaskedPhoneNumber(challenge.attributes.maskedPhoneNumber);
            }
            if (challenge.classifiers.indexOf('CHALLENGE_CLASSIFIER-USER_INTERACTION_TYPE:USER_INPUT')) {
                setLayoutAttributes(challenge.attributes)
                challenge.classifiers.map((classifier: any) => {
                    if (classifier === 'CHALLENGE_CLASSIFIER-USER_INTERACTION_TYPE:USER_INPUT') {
                        setCodeInput(true)
                    }
                })
            }
        })
    }

    function makeDecision() {
        let tempChallenge: any = tempChallengeDataBeforeDecision;
        tempChallenge.attributes['selectedIds'] = ["DECISION:UNLOCK"]
        let secondChallengeToResponse = [Base64.encodeURI(JSON.stringify(tempChallenge))];
        if (props.step === 1) {
            localStorage.setItem(`second-${props.documentId}`, Base64.encodeURI(JSON.stringify(tempChallenge)));
        }
        props.setGetNextChallenge({firstChallengeToResponse, secondChallengeToResponse})
    }

    function sendUnlock() {
        let tempChallenge: any = tempChallengeDataAfterDecision;
        tempChallenge.attributes['input'] = secondTextFieldValue
        let fourthChallengeToResponse = [Base64.encodeURI(JSON.stringify(tempChallenge))];
        // setSecondChallengeToResponse(encoded);
        props.setotpStep(2);
        props.setGetNextChallenge({thirdChallengeToResponse, fourthChallengeToResponse})
    }

    function sendCode() {

    }

    useEffect(() => {
        getAction()
    }, [props.otpChallange])
    const onChangeSecondTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (!newValue || newValue.length <= 6) {
                setSecondTextFieldValue(newValue || '');
            }
        },
        [],
    );
    return (
        <Stack styles={stackStyles} horizontalAlign="center" verticalFill={true} grow>
            <Stack verticalAlign="center" grow horizontalAlign={"center"} styles={wrapperStyles}>
                <Stack.Item align="center" styles={alignStyles}>
                    <h2>{t('Otp.AccessRestricted')}</h2>
                    {organizationName &&
                        <p>{t('Otp.AccessRestrictedText1')} <b>{organizationName}</b> {t('Otp.AccessRestrictedText2')}
                            <b>{maskedPhoneNumber}</b></p>}
                </Stack.Item>
                {!codeInput && <Stack.Item align="center">
                    <DefaultButton onClick={makeDecision} text={t('Otp.Unlock')}/>
                </Stack.Item>}
                {codeInput && <Stack.Item align="center" styles={alignStyles}>
                    <Stack styles={marginStyles}>
                        <TextField
                            label=""
                            value={secondTextFieldValue}
                            onChange={onChangeSecondTextFieldValue}
                            styles={narrowTextFieldStyles}
                        />
                    </Stack>
                    <Stack>
                        <DefaultButton onClick={sendUnlock} text={t('Otp.SendCode')}/>
                    </Stack>
                </Stack.Item>}
            </Stack>
        </Stack>
    );
}
