import {
    Stack, DefaultPalette, IStackItemStyles, Image, IImageProps, ImageFit
} from '@fluentui/react';
import Moment from 'moment';
import React, {useEffect, useState} from 'react';
import Trash_ico from '../icons/Trash.svg';
import Archive_ico from '../icons/Archive.svg';
import Progressbar from './Progressbar';
import {useTranslation} from 'react-i18next';
import {getMessage} from './helpers/document-helper';

const stackItemStyles: IStackItemStyles = {
    root: {
        background: "#ffffff",
        color: DefaultPalette.black,
        display: 'block',
        padding: 10,
        alignItems: 'center',
        cursor: 'pointer',
        minHeight: 88
    },
};
const imageProps: IImageProps = {
    src: Archive_ico,
    imageFit: ImageFit.center,
};

function ListItem(props: any) {
    const {t, i18n} = useTranslation();
    const [signers, setSigners] = useState<any[]>();

    function getViewed() {
        var is_viewed: string = '';
        props.item.flags.map((item: any) => {
            if (item === 'FLAG:VIEWED') {
                return is_viewed = 'viewed';
            }
        })
        return is_viewed;
    }

    function addToArchive(id: string) {
        props.archive(id);
    }

    useEffect(() => {
        getSignersList(props.item.parties);
    }, []);

    function getSignersList(parties: any) {
        let Signers = [];
        let SignersCount = 0
        for (let iterator = 0; iterator < parties.length; iterator++) {
            if (parties[iterator].role === 'SIGNER' || parties[iterator].role === 'VIEWER') {
                SignersCount++;
                if (iterator <= 1) {
                    if (iterator === 0) {
                        Signers.push({
                            sender: '' + parties[iterator].party.firstName + ', '
                        })
                    } else {
                        Signers.push({
                            sender: '' + parties[iterator].party.firstName + ' '
                        })
                    }
                } else {
                    Signers.push({
                        sender: `${t('DocumentDetails.peopleCount')}${SignersCount - 2}${t('DocumentDetails.peopleCount2')}`
                    })
                    break;
                }

            }
        }
        setSigners(Signers);
    }


    return (
        <Stack>
            <Stack.Item grow={10} styles={stackItemStyles}
                        className={(props.item.active ? 'selected' : '') + ' ' + getViewed()}>
                <Stack>
                    <Stack horizontal>
                        <Stack.Item grow className='inbox-item-title'>
                            {props.item.title ? <span
                                    className="">{props.item.title.length > 60 ? props.item.title.substring(0, 63) + '...' : props.item.title}</span> :
                                <span className="">{t('DocumentsList.emtyTitleDraft')}</span>

                            }
                        </Stack.Item>
                        <Stack.Item className='inbox-item-date'>
                            <small>{Moment(props.item.createdAt).format('DD-MM-YYYY')}</small>
                        </Stack.Item>
                        {(!props.drafts && !props.item.archive) && <Stack.Item className='inbox-item-trash'>
                            <a href={'#'} onClick={() => addToArchive(props.item.id)}><Image {...imageProps}
                                                                                             width={20.3}
                                                                                             height={18.67}/></a>
                        </Stack.Item>}
                    </Stack>
                    <Stack horizontal className='item-text'>
                        <Stack.Item grow>
                            <Stack horizontal>
                                {!props.drafts &&
                                    <Stack.Item>
                                        <div className={'item-image' + ' ' + props.status.class}>
                                            <Image src={props.status.icon} imageFit={ImageFit.center}
                                                   className={props.status.class}/>
                                        </div>
                                    </Stack.Item>
                                }
                                <Stack.Item className='item-people' grow>
                                    <Stack>
                                        <Stack.Item className='item-sender'>
                                            <span>
                                                {props.item.parties.map((_party: any, i: any) => {
                                                    if (_party.role === 'SENDER') {
                                                        return ' ' + _party.party.name
                                                    }
                                                })}
                                            </span>
                                        </Stack.Item>
                                        <Stack.Item className='item-signer'>
                                            <small>to:</small>
                                            <span>{
                                                signers && signers.map((_party: any, i: any) => {
                                                        return ` ${_party.sender}`
                                                })
                                            }</span>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        {!props.drafts &&
                            <Stack.Item grow>
                                {getMessage(props.item, t).class !== '' ?
                                    <div className='item-status-status'>Status</div> : null}
                                <div
                                    className={"item-status-text " + getMessage(props.item, t).class}>{getMessage(props.item, t).message}</div>
                                {getMessage(props.item, t).class === '' ? (!props.listView &&
                                    <Progressbar item={props.item}/>) : null}

                            </Stack.Item>
                        }
                    </Stack>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

export default ListItem
