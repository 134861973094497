import {AutentiToken, MsTokenV1, MsTokenV2} from "./helpers/tokens/TokensHelper";
import React, {useEffect, useState} from "react";
import {DefaultButton, Image, ImageFit, IStackItemStyles, Stack} from "@fluentui/react";
import {Spinner, SpinnerSize} from "@fluentui/react/lib/Spinner";
import Sign_svg from "../icons/Podpisywanie.svg";
import Login from "./Login";
import {useTranslation} from "react-i18next";
import {InteractionType} from "@azure/msal-browser";
import {MsalAuthenticationResult, MsalAuthenticationTemplate, useIsAuthenticated, useMsal} from "@azure/msal-react";


const loginContainer: IStackItemStyles = {
    root: {
        padding: 16
    },
};

const stackItemStyles: IStackItemStyles = {
    root: {
        padding: 8,
        textAlign: "center"
    },
};

const spinerWrpper: IStackItemStyles = {
    root: {
        margin: 'auto',
        marginLeft: 10
    },
};
const goBackButton: IStackItemStyles = {
    root: {
        width: "fit-content",
        margin: 16
    }
}

function SharePoint(props: any) {

    const [login, setLogin] = useState<boolean>(false);
    const [loginState, setLoginState] = useState<boolean>(false);
    const [refreshButton, setRefreshButton] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const isAuthenticated = useIsAuthenticated();
    const {instance, accounts, inProgress} = useMsal();

    const [Message, setMessage] = useState<string>('Wait')

    const {t, i18n} = useTranslation();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(decodeURI(queryString));
    const fileName: string = urlParams.get("FileLeafRef") || "";
    const listId: string = urlParams.get("SPListId") || "";
    const fileId: any = urlParams.get("SPListItemId")?.split(',') || "";
    const siteUrl: string = urlParams.get("SPSiteUrl") || "";
    const sessionStorage: any = window.sessionStorage;


    const createUrl = process.env.REACT_APP_CREATE_LOGIN_BASE_URL + '/app/login?accessTokenCookieName=Z-YXV0ZW50aQ-token&useLoggedInUser=true&successUrl=' + process.env.REACT_APP_CREATE_REDIRECT_DOMAIN;

    function clearTokensFromSessionStorage() {
        setLoading(true)
        let clearTokenProcess: any = window.sessionStorage.getItem('clearTokenProcess');
        if (clearTokenProcess < 4) {
            clearTokenProcess++
            for (let itemStorage in sessionStorage) {
                let getItem: any = window.sessionStorage.getItem(itemStorage);
                let jsonOfItem: any = JSON.parse(getItem);
                if (jsonOfItem) {
                    for (let item in jsonOfItem) {
                        if (item === "credentialType" && jsonOfItem[item] === "AccessToken") {
                            window.sessionStorage.removeItem(itemStorage)
                            window.sessionStorage.setItem('clearTokenProcess', clearTokenProcess)
                            setTimeout(() => {
                                window.location.reload();
                            }, 5000)
                        }
                    }
                }
            }
        } else {
            setMessage(t('SharePoint.Error'))
        }
        //

    }

    async function createDraft(token: string, msTokenv1: string, msTokenv2: string) {
        fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                "title": "New draft"
            })
        })
            .then(response => {
                return response.json();
            })
            .then(json => {
                uploadFile(json.id, msTokenv1, msTokenv2, token);
            }).catch(error => {
            console.log(error);
        });
    }

    async function uploadFile(documentProcessId: string, msTokenv1: string, msTokenv2: string, token: string) {
        for (let itemId in fileId) {
            const regex = /([{}])/gm;
            let splitUrl = siteUrl.split('/')
            let location: any = '';
            let idSite: any;
            let siteAddr: any;
            if (splitUrl[4]) {
                siteAddr = `https://graph.microsoft.com/v1.0/sites/${splitUrl[2]}:/sites/${splitUrl[4]}?$select=id`
            } else {
                siteAddr = `https://graph.microsoft.com/v1.0/sites/${splitUrl[2]}:?$select=id`
            }

            await fetch(siteAddr, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + msTokenv1
                },
            }).then((res) => {
                return res.json()
            }).then((json) => {
                idSite = json.id;
            }).catch(error => {
                console.log(error);
                clearTokensFromSessionStorage();
            })

            if (!idSite) {
                setRefreshButton(true);
                setLoading(false);
                return
            }
            let splitSiteId = idSite.split(',')
            const decodedListId = decodeURI(listId)
            decodedListId.replace(regex, '')
            let FileName: any = '';
            // await fetch( `https://${splitUrl[2]}/sites/${splitUrl[4]}/_api/web/lists(guid'${listId}')/items(${fileId})/File`, {
            await fetch(`https://graph.microsoft.com/v1.0/sites/${splitSiteId[1]}/lists/${decodedListId.replace(regex, '')}/items/${fileId[itemId]}/driveItem/content`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + msTokenv1
                },
            })
                .then((res) => {
                    location = res.url

                })
                .catch(error => {
                    console.log(error);
                });
            await fetch(`https://graph.microsoft.com/v1.0/sites/${splitSiteId[1]}/lists/${decodedListId.replace(regex, '')}/items/${fileId[itemId]}/driveItem/name`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + msTokenv1
                },
            })
                .then((res) => {
                    return res.json()
                }).then((json) => {
                    FileName = json.value
                })
                .catch(error => {
                    console.log(error);
                });


            try {
                const { formData, mimeType } = await downloadFileFromSharePoint(location, msTokenv1, FileName)
                formData.append('mimeType', mimeType); // dodajemy informację o typie MIME do formData
                await sendFileToAPI(formData, `${process.env.REACT_APP_API_AUTENTI}/api/v2/document-processes/${documentProcessId}/files`, token);
            } catch (error) {
                console.error(error);
            }

        }
        setMessage('Redirect to draft...')
        getJwtRedirectToken(documentProcessId, token);
    }

    async function downloadFileFromSharePoint(fileUrl: string, accessToken: string, FileName : string): Promise<{ formData: FormData, mimeType: string }> {
        const response = await fetch(fileUrl, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        if (!response.ok) {
            throw new Error(`Failed to download file: ${response.status} ${response.statusText}`);
        }
        const blob = await response.blob();
        const fileName = FileName;
        const mimeType = response.headers.get('content-type')!;
        const file = new File([blob], fileName, { type: mimeType });
        const formData = new FormData();
        formData.append('file', file);
        return { formData, mimeType };
    }

    async function sendFileToAPI(formData: FormData, apiUrl: string, accessToken: string): Promise<void> {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        });
        if (!response.ok) {
            throw new Error(`Failed to send file to API: ${response.status} ${response.statusText}`);
        }
        console.log('File sent to API successfully!');
    }

    async function getJwtRedirectToken(draftId: string, token: string) {
        const cookieName = localStorage.getItem('cookie_name') || ''
        const windowLocation = encodeURI(window.location.origin + '/close')
        let finalId = draftId.split(':')

        let response = await fetch('/api/token/encode?cookieName=' + cookieName + '&redirectUrl=' + createUrl + '/' + finalId[1] + '?returnTo=' + windowLocation, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ` + token
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(json => {
            const redirect = window.location.origin + '/api/token/decode?token=' + json.token
            window.location.replace(redirect);
            // runTeamsTask(redirect);
        }).catch(error => {
            console.log('error', error)
        })
    }

    async function init() {
        setLoading(true)
        setLogin(false)
        if (fileId[0] === 'null') {
            setMessage('Error, select file to sign!')
            return;
        }
        const msToken1 = await MsTokenV1();
        const msToken2 = await MsTokenV2();

        let letAutentiTokenValue = '';
        const Autenti = await AutentiToken(msToken2).then(token => {
            letAutentiTokenValue = token;
        }).catch(error => {
            setLogin(true)
        });
        await createDraft(letAutentiTokenValue, msToken1, msToken2);

    }

    useEffect(() => {
        init();
    }, [loginState]);

    function goback() {
        window.history.back()
    }

    const request = {
        scopes: ["https://graph.microsoft.com/.default"],
    };
    return (
        <><DefaultButton styles={goBackButton} onClick={goback}>{t('TopBar.GoBack')}</DefaultButton>
            <Stack grow styles={loginContainer} wrap>
                <Stack verticalAlign="center" grow className="container" wrap>
                    <Stack.Item align="center" className="container">

                        <Stack className="ident" grow horizontal wrap>
                            <Stack grow horizontal horizontalAlign="center" wrap>
                                <Stack.Item grow verticalFill className="image-wrapper">
                                    <Image src={Sign_svg} imageFit={ImageFit.center} width={328}
                                           height={304}/>
                                </Stack.Item>
                                <Stack.Item grow verticalFill>
                                    <Stack>
                                        {login ? <Login loginState={setLoginState}/> :

                                            <Stack styles={spinerWrpper}>
                                                <Stack.Item styles={stackItemStyles}>{Message}</Stack.Item>
                                                <Stack.Item styles={stackItemStyles}>{loading &&
                                                    <Spinner size={SpinnerSize.large}/>}</Stack.Item>
                                                <Stack.Item styles={stackItemStyles}>{refreshButton && <DefaultButton
                                                    onClick={clearTokensFromSessionStorage}>{t('SharePoint.Button')}</DefaultButton>}</Stack.Item>
                                                <Stack.Item styles={stackItemStyles}>{!isAuthenticated && <DefaultButton
                                                    onClick={() => instance.loginPopup()}>{t('SharePoint.LoginToMicrosoft')}</DefaultButton>}</Stack.Item>
                                                <Stack.Item styles={stackItemStyles}>
                                                    <MsalAuthenticationTemplate interactionType={InteractionType.Popup}
                                                                                authenticationRequest={request}
                                                                                errorComponent={ErrorComponent}
                                                                                loadingComponent={LoadingComponent}>
                                                    </MsalAuthenticationTemplate>
                                                </Stack.Item>

                                            </Stack>}
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
}

function ErrorComponent(error: MsalAuthenticationResult) {
    return <p>An Error Occurred: {error.error?.errorMessage}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default SharePoint;
