import React from 'react';

type NextChallenge = {
    firstChallengeToResponse?: any[],
    secondChallengeToResponse?: any[],
    thirdChallengeToResponse?: any[],
    fourthChallengeToResponse?: any[]
}

export default function HeadersHelper(otpStep: number, id: string, token: string, getNextChallenge: NextChallenge) {
    const prevChallengeOne = localStorage.getItem(`first-${id}`) || ''
    const prevChallengeTwo = localStorage.getItem(`second-${id}`) || ''
    let headers;
    headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + token,

    }
    if (otpStep === 1) {
        return headers = [
            ['X-ASSERTION', getNextChallenge.firstChallengeToResponse?.toString() || ''],
            ['X-ASSERTION', getNextChallenge.secondChallengeToResponse?.toString() || ''],
            ['Accept', 'application/json'],
            ['Authorization', 'Bearer ' + token,]
        ]
    } else {
        return headers = [
            ['X-ASSERTION', prevChallengeOne],
            ['X-ASSERTION', prevChallengeTwo],
            ['X-ASSERTION', getNextChallenge.thirdChallengeToResponse?.toString() || ''],
            ['X-ASSERTION', getNextChallenge.fourthChallengeToResponse?.toString() || ''],
            ['Accept', 'application/json'],
            ['Authorization', 'Bearer ' + token,]
        ]
    }
}
