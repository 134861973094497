import { Stack, IStackItemStyles, Persona, PersonaPresence, PersonaSize, IStackTokens } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Recipients from './Recipients';


const stackChildItemStyles: IStackItemStyles = {
    root: {
        paddingLeft: 10,
        paddingRight: 10
    },
};
const personaStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

function DocumentDetails(props: any) {
    const { t, i18n } = useTranslation();
    const [renderDetails, updateRenderDetails] = useState<boolean>(true);
    let element : HTMLElement  =   document.querySelector('.details-message')  || document.createElement('div');
    element.innerHTML = props.documentItem.description;
    return (
        <Stack>
            <Stack className='document-sender-container'>
                <Stack.Item><p className="bold">{t('DocumentDetails.sender')}</p></Stack.Item>
                <Stack.Item className='document-sender'>
                    <Stack horizontal>
                        <Stack.Item styles={stackChildItemStyles} grow>
                            <Persona
                                {...props.sender}
                                size={PersonaSize.size40}
                                presence={PersonaPresence.none}
                                hidePersonaDetails={!renderDetails}
                                imageAlt="Annie Lindqvist, status is away"
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <div className='company-name'>{props.sender?.tertiaryText ? props.sender?.tertiaryText : t('Settings.naturalReprezentation')}</div>
                            <div className='company-taxid'>{t('DocumentDetails.nip')} {props.sender?.optionalText ? props.sender?.optionalText : ''}</div>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item className='details-message'>
                    {/* <p>{props.documentItem.description }</p> */}
                    {document.createElement('div').innerHTML = props.documentItem.description}
                </Stack.Item>
            </Stack>
            <Stack tokens={personaStackTokens}>
                <Stack.Item><p className="bold">{t('DocumentDetails.detailsTabSignerTitle')}</p></Stack.Item>
                {props.recipients.map((recipient: any, i: any) => {
                    return (
                        <Recipients key={i} recipient={recipient}></Recipients>
                    );
                })}
            </Stack>
        </Stack>
    );
}
export default DocumentDetails