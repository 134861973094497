import React from 'react';
import jwt_decode from "jwt-decode";
import {loginRequest, msalConfig} from "../../../authConfig";
import {PublicClientApplication} from "@azure/msal-browser";
import {app, authentication} from "@microsoft/teams-js";
import Context = app.Context;

let teams:boolean = true;
 app.initialize().catch(e => {
     if(e){
         teams = false;
     }
})

export async function TeamsMsToken() {
    if (!teams){
        return await MsTokenV2();
    }

    const context: Context = await app.getContext()
    return new Promise<string>(resolve => {
        authentication.getAuthToken().then((token: string) => {
            const decoded: { [key: string]: any; } = jwt_decode(token) as { [key: string]: any; };
            localStorage.setItem('oid', decoded!.oid);
            localStorage.setItem('aud', decoded!.aud);
            localStorage.setItem('tid', decoded!.tid);
            localStorage.setItem('msToken', token);
            app.notifySuccess();
            resolve(token)
        })
            .catch((message) => {
                app.notifyFailure({
                    reason: app.FailedReason.AuthFailed,
                    message
                });
                resolve(message)
            });
    });
}

export function MsTokenV1() {

    return new Promise<string>(resolve => {
        const msalInstance = new PublicClientApplication(msalConfig);
        const account = msalInstance.getAllAccounts();
        const request = {
            scopes: ["https://graph.microsoft.com/.default"],
            account: account[0]
        };
        let jwt: any;
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        msalInstance.acquireTokenSilent(request).then((response) => {
            jwt = jwt_decode(response.accessToken);
            localStorage.setItem('oid', jwt.oid);
            localStorage.setItem('aud', jwt!.aud);
            localStorage.setItem('tid', jwt.tid);
            resolve(response.accessToken);
        }).catch((e) => {
            msalInstance.acquireTokenPopup(request).then((response) => {
                jwt = jwt_decode(response.accessToken);
                localStorage.setItem('oid', jwt.oid);
                localStorage.setItem('aud', jwt!.aud);
                localStorage.setItem('tid', jwt.tid);
                resolve(response.accessToken);
            }).catch((e) => {
                console.log(e)
            });
        });
    });
}

export function MsTokenV2() {
    return new Promise<string>(resolve => {
        const msalInstance = new PublicClientApplication(msalConfig);
        const account = msalInstance.getAllAccounts();

        const request = {
            ...loginRequest,
            account: account[0]
        };
        let jwt: any;
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        msalInstance.acquireTokenSilent(request).then((response) => {
            jwt = jwt_decode(response.accessToken);
            localStorage.setItem('oid', jwt.oid);
            localStorage.setItem('aud', jwt!.aud);
            localStorage.setItem('tid', jwt.tid);
            resolve(response.accessToken);
        }).catch((e) => {
            console.log(e);
            msalInstance.acquireTokenPopup(request).then((response) => {
                jwt = jwt_decode(response.accessToken);
                localStorage.setItem('oid', jwt.oid);
                localStorage.setItem('aud', jwt!.aud);
                localStorage.setItem('tid', jwt.tid);
                resolve(response.accessToken);
            });
        });
    });
}

function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts?.pop().split(';').shift();
    } else {
        throw new Error();
    }
}

export function AutentiToken(msToken: string, redirect?: boolean) {
    return new Promise<string>(async (resolve ,reject) => {
        try {
            if (localStorage.getItem('cookie_name') === 'logout') {
                resolve('logout');
            } else {
                const cookieToken = getCookie(localStorage.getItem('cookie_name') || '')
                resolve(cookieToken);
            }
        } catch {
            fetch('/api/user/token', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ` + msToken
                },
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(json => {
                    localStorage.setItem('cookie_name', json.cookieName);
                    localStorage.setItem('differentAccount', json.differentAccount);
                    localStorage.setItem('needLogin', 'false');
                    resolve(json.token);
                }).catch(error => {
                if(!(/settings/i).test(window.location.href)){
                    app.openLink('https://teams.microsoft.com/l/entity/' + process.env.REACT_APP_TEAMS_APP_ID + '/autenti_settings');
                }

                reject(error);
            });
        }
    });
}
