import {Stack, ProgressIndicator} from '@fluentui/react';


function Progressbar(props: any){
    return(
        <Stack className='progressbar-container'>
            <Stack.Item grow className='progressbar-indicator'>
                <ProgressIndicator label="" description="" percentComplete={props.item.parties.filter((item: { role: string | string[]; }) => item.role.indexOf('SIGNER') >= 0)
                    .filter((item: { participationStatus: string | string[]; }) => item.participationStatus.indexOf('COMPLETED') >= 0).length / props.item.parties.filter((item: { role: string | string[]; }) => item.role.indexOf('SIGNER') >= 0).length} />
            </Stack.Item>
            <Stack.Item className='progressbar-text'>
                <small>{props.item.parties.filter((item: { role: string | string[]; }) => item.role.indexOf('SIGNER') >= 0)
                    .filter((item: { participationStatus: string | string[]; }) => item.participationStatus.indexOf('COMPLETED') >= 0).length}/{props.item.parties.filter((item: { role: string | string[]; }) => item.role.indexOf('SIGNER') >= 0).length}</small>
            </Stack.Item>
    </Stack>
    );
}
export default Progressbar