import {Stack, IStackItemStyles, DefaultButton, List, DefaultPalette, IButtonStyles} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";


const stackItemStyles: IStackItemStyles = {
    root: {
        background: "#ffffff",
        color: DefaultPalette.black,
        display: 'block',
        padding: 10,
        alignItems: 'center',
    },
};
const stackItemStylesButtonWrapper: IStackItemStyles = {
    root: {
        background: "#ffffff",
        color: DefaultPalette.black,
        display: 'block',
        padding: 10,
        alignItems: 'center',
        // verticalAlign: 'center'
    },
};
const buttonStyles: IButtonStyles = {
    root: {
        height: '100%',
        alignSelf: "end",
        minHeight: 30
    },
}

function FileList(props: any) {
    const {t, i18n} = useTranslation();
    const [successSave, setSuccessSave] = useState<boolean>(false);
    const [version, setVersion] = useState<number>(1);

    const Download = async (id: string, filename: string) => {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + props.id + '/files/' + id.replace('/', '%2F') + '/content', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                // 'Range': 'bytes=200-',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(resp => resp.arrayBuffer()).then(resp => {

                const file = new Blob([resp], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = filename;
                link.click();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            });
    }

    function SaveToOneDrive(id: string, filename: string) {
        setSuccessSave(true);
        fetch('/api/Autenti/save-to-onedrive?documentId=' + props.id + '&id=' + id.replace('/', '%2F') + '&fileName=' + filename, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('msToken')
            },
        })
            .then(resp => {
                if (resp.status === 500) {
                    console.log('to tu')
                    props.promptForConsent((error: any) => {
                        if (error) {
                            console.info(error);
                        }
                    });
                }
                return resp.ok
            }).then((ok) => {
            setSuccessSave(true);
            setVersion(2);
        }).catch(error => {

        });
        setSuccessSave(successSave => (successSave = true));
        return true
    }

    useEffect(() => {
        setSuccessSave(false)
        setVersion(1);
    }, [props.id]);

    const onRenderCell = (item: any, index: number | undefined): JSX.Element => {
        if (props.documentStatus === 'completed' && item.filePurpose === 'SIGNED_CONTENT_FILE') {
            return (

                <Stack grow horizontal className='file-container' wrap>
                    <Stack.Item styles={stackItemStyles}>
                        <div className='file-ico'><span>{item.filename.split('.')[1].toUpperCase()}</span></div>
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles} grow className='file-title'>{item.filename}</Stack.Item>
                    <Stack.Item styles={stackItemStylesButtonWrapper}> <DefaultButton styles={buttonStyles}
                                                                                      onClick={() => Download(item.id, item.filename)}>{t('DocumentDetails.download')}</DefaultButton>
                    </Stack.Item>
                    <Stack.Item styles={stackItemStylesButtonWrapper}> <DefaultButton styles={buttonStyles}
                                                                                      onClick={() => SaveToOneDrive(item.id, item.filename)}>{successSave ? t('Download.succes') : t('Download.savetoonedrive')}</DefaultButton>
                    </Stack.Item>
                </Stack>

            );
        } else if ((props.documentStatus === 'processing' && item.filePurpose === 'PARTIALLY_SIGNED_CONTENT_FILE')) {
            return (
                <Stack grow horizontal className='file-container' wrap>
                    <Stack.Item styles={stackItemStyles}>
                        <div className='file-ico'><span>{item.filename.split('.')[1].toUpperCase()}</span></div>
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles} grow className='file-title'>{item.filename}</Stack.Item>
                    <Stack.Item styles={stackItemStylesButtonWrapper}> <DefaultButton styles={buttonStyles}
                                                                                      onClick={() => Download(item.id, item.filename)}>{t('DocumentDetails.download')}</DefaultButton>
                    </Stack.Item>
                    <Stack.Item styles={stackItemStylesButtonWrapper}> <DefaultButton styles={buttonStyles}
                                                                                      onClick={() => SaveToOneDrive(item.id, item.filename)}>{successSave ? t('Download.succes') : t('Download.savetoonedrive')}</DefaultButton>
                    </Stack.Item>
                </Stack>
            );
        } else {
            return (
                <>
                    {props.draft && <Stack grow horizontal className='file-container' wrap>
                        <Stack.Item styles={stackItemStyles}>
                            <div className='file-ico'><span>{item.filename.split('.')[1].toUpperCase()}</span></div>
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles} grow className='file-title'>{item.filename}</Stack.Item>
                        <Stack.Item styles={stackItemStylesButtonWrapper}> <DefaultButton styles={buttonStyles}
                                                                                          onClick={() => Download(item.id, item.filename)}>{t('DocumentDetails.download')}</DefaultButton>
                        </Stack.Item>
                    </Stack>}
                </>
            );
        }
    }
    return (
        <Stack grow={12}>
            <Stack.Item grow styles={{root: {width: 'fit-content', display: 'contents'}}}>
                <List items={props.files} onRenderCell={onRenderCell} version={version}/>
            </Stack.Item>
        </Stack>
    );
}

export default FileList
