import {
    Stack,
    IStackTokens,
    DefaultButton,
    IconButton,
    IButtonStyles,
    IOverflowSetItemProps,
    Link,
    OverflowSet
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import ListItem from './ListItem';
import '../styles/Inbox.css';
import {getStatus} from './helpers/document-helper';
import {useTranslation} from 'react-i18next';
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";

const outerStackTokens: IStackTokens = {
    childrenGap: 25,
};
const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return (
        <Link role="menuitem" styles={{root: {marginRight: 10}}} onClick={item.onClick} className={item.className}>
            {item.name}
        </Link>
    );
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 0,
            padding: '10px 10px !important',
            alignSelf: 'stretch',
            height: 'auto',
        },
    };
    return (
        <IconButton
            role="menuitem"
            title="More options"
            styles={buttonStyles}
            menuIconProps={{iconName: 'More'}}
            menuProps={{items: overflowItems!}}
        />
    );
};

function Inbox(props: any) {

    const {t, i18n} = useTranslation();
    const [items, setItems] = useState<any>([]);
    const [searchFail, setSearchFail] = useState<boolean>(false);
    const [statusFlag, setStatusFlag] = useState<any>([]);
    const [documentArchived, setDocumentArchived] = useState<number>(0);
    const [flagArchive, setFlagArchive] = useState<boolean>(false);

    const createUrl = process.env.REACT_APP_CREATE_LOGIN_BASE_URL + '/app/login?accessTokenCookieName=' + localStorage.getItem('cookie_name') + '&useLoggedInUser=true&successUrl=' + process.env.REACT_APP_CREATE_REDIRECT_DOMAIN;
    const createUrlRedirect = process.env.REACT_APP_CREATE_REDIRECT_DOMAIN;


    const fetchData = async () => {

        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        var query = param(props.searchQuery)
        var status = '';
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tab: string = urlParams.get('tab') || 'false';
        switch (tab) {
            case 'drafts':
                status = 'DRAFT';
                break;
            case 'sent':
                status = 'DOCUMENT_PROCESS'
                query = 'senderEmail=' + localStorage.getItem('userPrincipalName');
                break;
            case 'received':
                status = 'DOCUMENT_PROCESS'
                query = 'recipientEmail=' + localStorage.getItem('userPrincipalName');
                break;
            default:
                status = 'DOCUMENT_PROCESS'
        }

        if ((/drafts/i).test(tab)) {
            status = 'DRAFT';
        } else {
            status = 'DOCUMENT_PROCESS';
        }
        let tempData: any[] = [];
        return await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes?status=' + status + '&limit=' + props.step + '&' + query + '&statusFlags=' + statusFlag, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(json => {
                let index = 0
                if (!(/ARCHIVED/i).test(statusFlag)) {
                    for (let item of json) {
                        console.log(item.flags.length)
                        console.log('FLAG:ARCHIVED', item.flags.indexOf('FLAG:ARCHIVED'))
                        if (item.flags.indexOf('FLAG:ARCHIVED') === -1) {
                            tempData.push(item)
                        }
                    }
                    // setDocumentArchived(1);
                } else {
                    for (let item of json) {
                        item.archive = true;
                        tempData.push(item)
                    }
                    // setDocumentArchived(1);
                }

                if (!props.lastId || documentArchived > 0 || props.step === 22) {
                    props.setDocId(tempData[0].id);
                    setDocumentArchived(0);
                }
                setItems(tempData);
                if (json.length === 0) {
                    setSearchFail(true);
                } else {
                    setSearchFail(false);
                }
            });

    }
    useEffect(() => {
        fetchData();
    }, [props.step, props.searchQuery, props.tokenIsFresh, props.doReload, statusFlag, documentArchived]);


    const setDocument = (id: string) => {
        props.setDocId(id);
        var new_items = items;
        new_items.map(function (item: any, index: number) {
            if (item.id === id) {
                item.active = true;
            } else {
                item.active = false;
            }
        })
        setItems(new_items);
    }

    function param(object: any) {
        var parameters = [];
        for (var property in object) {
            if (object[property] !== '' && object[property] !== undefined) {
                if (object.hasOwnProperty(property)) {
                    parameters.push(encodeURI(property + '=' + object[property]));
                }
            }
        }

        return parameters.join('&');
    }

    function addStatusFlag(flagName: string) {
        if (statusFlag.length > 0) {
            var tmpFlags = statusFlag;
            const Idx = tmpFlags.findIndex(
                (item: any) => item === flagName,
            );
            if (Idx >= 0) {
                tmpFlags.splice(Idx, 1);
                setStatusFlag((test: any) => ([...tmpFlags]))
                return
            }
        }
        setStatusFlag((test: any) => ([...test, flagName]))
    }

    async function Archive(id: string) {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + id + '/flags/FLAG:ARCHIVED', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => response)
            .then(json => {

            }).catch(error => {
            console.log(error);
        });
        setDocumentArchived(5);
        props.reload(true);
    }

    const setClassName = (flagName: string) => {
        if (statusFlag.findIndex(
            (item: any) => item === flagName,
        ) >= 0) {
            return 'active-flag'
        }
    }

    async function getJwtRedirectToken() {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        const cookieName = localStorage.getItem('cookie_name') || ''
        const windowLocation = encodeURI(createUrl + '?returnTo=' + window.location.protocol + '//' + window.location.host + '/close')

        let response = await fetch('/api/token/encode?cookieName=' + cookieName + '&redirectUrl=' + windowLocation, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ` + getToken
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(json => {
            const redirect = window.location.origin + '/api/token/decode?token=' + json.token
            window.open(redirect, '_blank');
        }).catch(error => {
        })
    }

    function handleSetFlagArchive() {
        setFlagArchive(prevState => (!prevState))
        fetchData()
    }

    return (
        <Stack grow>
            <Stack.Item>
                <Stack className='inbox-items-container'>
                    {props.drafts && <Stack.Item className="inbox-create-container">
                        <DefaultButton className="inbox-create-button" onClick={getJwtRedirectToken}>
                            {t('Sidebar.appNewDocument')}
                        </DefaultButton>
                    </Stack.Item>}
                    {!props.drafts && <Stack.Item>
                        <OverflowSet className="status-flag-wrapper"
                                     aria-label="Basic Menu Example"
                                     role="menubar"
                                     items={[
                                         {
                                             key: 'WAITING_FOR_ME',
                                             name: t('StatusFlag.WaitingForMe'),
                                             onClick: () => addStatusFlag('WAITING_FOR_ME'),
                                             className: setClassName('WAITING_FOR_ME')
                                         },
                                         {
                                             key: 'WAITING_FOR_OTHERS',
                                             name: t('StatusFlag.WaitingForOthers'),
                                             onClick: () => addStatusFlag('WAITING_FOR_OTHERS'),
                                             className: setClassName('WAITING_FOR_OTHERS')
                                         },
                                         {
                                             key: 'FOR_APPROVAL',
                                             name: t('StatusFlag.ForApproval'),
                                             onClick: () => addStatusFlag('FOR_APPROVAL'),
                                             className: setClassName('FOR_APPROVAL')
                                         },
                                     ]}
                                     overflowItems={[
                                         {
                                             key: 'FINISHED_WITHDRAWN',
                                             name: t('StatusFlag.FinishedWithdrawn'),
                                             onClick: () => addStatusFlag('FINISHED_WITHDRAWN'),
                                             className: setClassName('FINISHED_WITHDRAWN')
                                         },
                                         {
                                             key: 'FOR_REVIEW',
                                             name: t('StatusFlag.ForReview'),
                                             onClick: () => addStatusFlag('FOR_REVIEW'),
                                             className: setClassName('FOR_REVIEW')
                                         },
                                         {
                                             key: 'FINISHED_SIGNED',
                                             name: t('StatusFlag.FinishedSigned'),
                                             onClick: () => addStatusFlag('FINISHED_SIGNED'),
                                             className: setClassName('FINISHED_SIGNED')
                                         },
                                         {
                                             key: 'FINISHED_REJECTED',
                                             name: t('StatusFlag.FinishedRejected'),
                                             onClick: () => addStatusFlag('FINISHED_REJECTED'),
                                             className: setClassName('FINISHED_REJECTED')
                                         },
                                         {
                                             key: 'ARCHIVED',
                                             name: t('StatusFlag.Archived'),
                                             onClick: () => addStatusFlag('ARCHIVED'),
                                             className: setClassName('ARCHIVED')
                                         },
                                     ]}
                                     onRenderOverflowButton={onRenderOverflowButton}
                                     onRenderItem={onRenderItem}
                        />
                    </Stack.Item>}
                    {!searchFail ? items.map((item: any, i: any) => {
                        return (
                            <Stack.Item key={i} onClick={() => setDocument(item.id)} tokens={outerStackTokens}
                                        className='inbox-item-container'>
                                <ListItem token={props.token} item={item} status={getStatus(item.status)}
                                          documentView={props.documentView} listView={props.listView}
                                          drafts={props.drafts} archive={(id: string) => Archive(id)}></ListItem>
                            </Stack.Item>
                        );
                    }) : <Stack.Item></Stack.Item>}
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

export default Inbox
