import React, {useEffect, useState} from "react";
import {
    Stack,
    DefaultButton,
    IStackItemStyles,
    IIconProps, getTheme, mergeStyleSets, FontWeights, IButtonStyles
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import "../styles/Settings.css";
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";
import {app} from "@microsoft/teams-js";


const cancelIcon: IIconProps = {iconName: 'Cancel'};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        maxWidth: '1000px'
    },
    header: [

        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        // textAlign: 'center',
        selectors: {
            p: {margin: '16px 8px'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
});
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const loginWrapper: IStackItemStyles = {
    root: {
        marginTop: 30,
        marginBottom: 20,
        paddingBottom: 20,
        borderBottom: "solid 1px #ccc"
    },
};
const loginItem: IStackItemStyles = {
    root: {
        padding: 8
    },
};
type Loadings = {
    loginWithAutentiFirst: boolean,
    loginWithAutentiSecond: boolean,
    loginWithMsAccount: boolean,
}
const spinerWrpper: IStackItemStyles = {
    root: {
        margin: 'auto',
        marginLeft: 10
    },
};
const buttonStyles: IButtonStyles = {
    root: {
        width: 'auto',
        height: 'auto',
        minHeight: 30,
    },
}

function Login(props: any) {
    const {t, i18n} = useTranslation();
    const [myName, setMyname] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loadinButton, setLoadingButton] = useState<Loadings>({
        loginWithAutentiFirst: false,
        loginWithAutentiSecond: false,
        loginWithMsAccount: false,
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    async function getMyName() {
        // if (localStorage.getItem('cookie_name') === null) {
        //     return
        // }
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken, true);
        setLoading(true)
        fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/me', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => {
                return response.json();
            })
            .then(json => {
                const emailSplit = json.id.split(":")
                setMyname(`${json.name} <${emailSplit[1]}>`);
                setLoading(false)
            }).catch(error => {
            console.log(error);
        });
    }

    function onClickLogin() {
        setLoadingButton({...loadinButton, loginWithAutentiFirst: true});
        // setLoading(true);
        localStorage.setItem('cookie_name', '');
        let interval = setInterval(checkLoginProcess, 5000);
        localStorage.setItem("login_state", "start");
        const redirect =
            `${window.location.origin}/api/Autenti/userAuthorizationUrl?userId=${localStorage.getItem("oid")}&tenantId=${localStorage.getItem("tid")}`;
        window.open(
            redirect,
            "Popup",
            "location,status,scrollbars,resizable,width=800, height=800"
        );
    }

    async function checkLoginProcess() {
        const loginState = localStorage.getItem("login_state");
        console.log(loginState);
        if (loginState === null) {
            props.loginState(true);
            localStorage.setItem("login_state", "loggedin");
            localStorage.setItem('needLogin', 'false');
            await app.openLink('https://teams.microsoft.com/l/entity/' + process.env.REACT_APP_TEAMS_APP_ID + '/autenti_inbox');
        }
        return true;
    }

    const logout = async (): Promise<any> => {
        setLoading(true);
        const msToken = await TeamsMsToken();
        setLoadingButton({...loadinButton, loginWithMsAccount: true});
        localStorage.clear();
        return new Promise((resolve) => {
            fetch("/api/user/logout", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ` + msToken
                },
            })
                .then((response) => {
                    if (response.status === 204) {
                        localStorage.clear();
                        window.location.reload();
                    } else if (response.status === 500) {
                        localStorage.clear();
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    function logoutAll() {
        localStorage.setItem('cookie_name', 'logout');
        setMyname('');
    }



    useEffect(() => {
        setLoading(true);
        getMyName()
    }, [props.update]);

    return (
        <Stack styles={loginWrapper} wrap className="container">
            {myName ?
                <Stack.Item styles={loginItem} className="settings-text-title">
                    {t('Settings.LoginByMs')} {myName} {loading && <Spinner size={SpinnerSize.large}/>}
                </Stack.Item>
                :
                <Stack.Item styles={loginItem} className="settings-text-title">
                    {t('Settings.NotLogin')}
                </Stack.Item>
            }
            <Stack grow horizontal horizontalAlign="center" verticalFill wrap className="container">
                <Stack.Item styles={loginItem} className="settings-text-title">
                    <DefaultButton onClick={onClickLogin} styles={buttonStyles}>
                        {myName ? t('Settings.LoginToOtherAccount') : t('Settings.Login')}
                        {loadinButton.loginWithAutentiFirst &&
                            <Stack styles={spinerWrpper}>
                                <Spinner size={SpinnerSize.small}/>
                            </Stack>}
                    </DefaultButton>
                </Stack.Item>
                {(myName !== '') && <Stack.Item styles={loginItem} className="settings-text-title">
                    <DefaultButton onClick={logout} styles={buttonStyles}>
                        {t('Settings.Logout')}
                        {loadinButton.loginWithAutentiFirst &&
                            <Stack styles={spinerWrpper}>
                                <Spinner size={SpinnerSize.small}/>
                            </Stack>}
                    </DefaultButton>
                </Stack.Item>}
            </Stack>
        </Stack>
    );
}

export default Login;
