import {Stack} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function Sign(props: any) {
    const [documentFile, setDocumentFile] = useState<any>([]);
    const [numPages, setNumPages] = useState<any>(null);
    const [width, setWidth] = useState<any>(null);

    function onDocumentLoadSuccess(numPages: any) {
        setNumPages(numPages._pdfInfo.numPages);
    }

    // const [file, setFile] = useState<any>();

    const fetchData = async (id: string) => {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        return await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + id + '/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => response.json())
            .then(json => {
                json.files.map((item: any, i: any) => {
                    if (props.documentStatus === 'completed' && item.filePurpose === 'SIGNED_CONTENT_FILE') {
                        Download(item.id, item.filename)
                    } else if (props.documentStatus === 'processing' && item.filePurpose === 'PARTIALLY_SIGNED_CONTENT_FILE') {
                        Download(item.id, item.filename)
                    }
                    // else if (props.documentStatus !== 'processing' || props.documentStatus !== 'completed' && item.filePurpose === 'SOURCE_FILE') {
                    //     Download(item.id, item.filename)
                    //     console.log(item.id)
                    // }
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    const setFlag = async (id: string) => {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        return await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + id + '/flags/FLAG:VIEWED', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => response.json())
            .then(json => {

            }).catch(error => {
                console.log(error);
            });
    }

    const Download = async (id: string, filename: string) => {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        return await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + props.id + '/files/' + id.replace('/', '%2F') + '/content', {
            method: 'GET',
            headers: {
                // 'Range': 'bytes=200-',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = URL.createObjectURL(blob);
                setDocumentFile({url: href});
            })
            .catch(error => {
                console.log(error);
            });
    }
    useEffect(() => {
        fetchData(props.id).then(resp => {
            calculateWidth();
            setFlag(props.id);
        })

    }, []);

    function calculateWidth() {
        var doc: any = document.getElementById('pdf-container');
        setWidth(doc.offsetWidth);
    }

    return (
        <Stack>
            <Stack.Item id='pdf-container'>
                <Document file={documentFile.url} className='pdf' onLoadError={console.error}
                          onLoadSuccess={onDocumentLoadSuccess} loading='<div className="loader">Loading...</div>'>
                    {Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page className="pdf-page" width={width}
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                            />
                        ),
                    )}
                </Document>
            </Stack.Item>
        </Stack>
    );
}

export default Sign;
