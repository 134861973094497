import Classic_Sign_ico from '../../icons/Classic_Sign.svg';
import Success_ico from '../../icons/Success.svg';
import Delete_ico from '../../icons/Delete.svg';
import Withdraw_ico from '../../icons/Withdraw.svg';

export const getMessage = (document, t) => {

    var message;
    document.parties.map((item) => {
        if (item.currentUser && item.events.length > 0) {
            item.events.map((event) => {
                if (event.eventType === 'EVENT_CLASSIFIER-UNIQUE_TYPE:SIGNATURE_APPLICATION' && document.status === 'COMPLETED') {
                    return message = { 'message': t('DocumentDetails.detailsDecisionResultSigned'), 'class': 'message-signed' };
                } else if (event.eventType === 'EVENT_CLASSIFIER-UNIQUE_TYPE:SIGNATURE_APPLICATION' && document.status === 'PROCESSING') {
                    return message = { 'message': t('DocumentDetails.detailsDecisionResultEnqueued'), 'class': '' };
                }
                if (event.eventType === 'EVENT_CLASSIFIER-UNIQUE_TYPE:SIGNATURE_REJECTION') {
                    return message = { 'message': t('DocumentDetails.detailsDecisionResultRejected'), 'class': 'message-rejected' };
                }
            });
        } else if (item.currentUser && item.events.length === 0) {
            return message = { 'message': t('VerticalCollapsedMenu.documentStatusWaitingForMe'), 'class': '' };
        } else if (item.currentUser && item.events.length !== 0) {
            return message = { 'message': t('VerticalCollapsedMenu.documentStatusWaitingForMe'), 'class': '' };
        }
        if (item.currentUser && item.role === 'VIEWER') {
            return message = { 'message': t('DocumentDetails.documentOnlyViewers'), 'class': '' };
        }
    })
    if (document.status === 'WITHDRAWN') {
        return message = { 'message': t('DocumentDetails.documentWithdrawn'), 'class': 'class' };
    }
    if (message === undefined) {
        message = { 'message': t('VerticalCollapsedMenu.documentStatusWaitingForMe'), 'class': '' };
    }
    return message;
}

export const getStatus = (status) => {
    switch (status) {
        case 'PROCESSING':
          return {
            'class': 'processing',
            'icon': Classic_Sign_ico,
          };
        case 'COMPLETED':
          return {
            'class': 'completed',
            'icon': Success_ico,
          };
        case 'REJECTED':
          return {
            'class': 'rejected',
            'icon': Delete_ico,
          };
        case 'WITHDRAWN':
          return {
            'class': 'withdrawn',
            'icon': Withdraw_ico,
          };
        case 'DRAFT':
          return {
            'class': 'draft',
            'icon': 'Dra',
          };
        case 'ERROR':
          return {
            'class': 'error',
            'icon': Delete_ico,
          };
      }
}

