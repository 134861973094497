import React, {useEffect, useState} from 'react';
import {
    Stack, IStackTokens,
    initializeIcons,
} from '@fluentui/react';
import './App.css';
import AutentiSettings from './components/Settings';
import Close from './components/helpers/views/Close';
import {
    Route, BrowserRouter, Routes
} from "react-router-dom";
import {Home} from "./components/Home";
import SharePoint from "./components/SharePoint";


initializeIcons();

const outerStackTokens: IStackTokens = {
    childrenGap: 0,
    maxHeight: '100%'
};


export const App: React.FunctionComponent = () => {
    const [listView, setListView] = useState(false);
    const [loginToApp, setLoginToApp]= useState(false);
    const size = useWindowSize();

    const init = async (): Promise<string> => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const settingsQuery = urlParams.get('tab');
        if(settingsQuery === 'settings'){
            window.location.replace('settings')
        }
        if (urlParams.get("loginsuccess")) {
            // window.close()
            if (localStorage.getItem('login_state') === null) {
                const encodedContext = encodeURI('{"subEntityId": "loggedin"}');
                setLoginToApp(false)
                window.location.replace(`https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/autenti_inbox?webUrl=${encodeURI(window.location.origin + '/loggedin')}&context=${encodedContext}`);
                return 'login';
            } else {
                localStorage.clear();
                localStorage.setItem("login_state", "done");
                window.close()
            }
        }
        setLoginToApp(true)
        if (localStorage.getItem('version') !== 'v3') {
            localStorage.clear();
        }
        if (localStorage.getItem('init') === null) {
            localStorage.setItem('init', 'true');
        }
        return 'de';
    }

    useEffect(() => {
        if (size.width <= 1024) {
            setListView(true)
        }
        let doc: any = document.getElementById('body-container') || document.createElement('div');
        let container: any = document.getElementById('container') || document.createElement('div');
        if (doc && container) {
            doc.style.height = window.innerHeight + 'px';
            doc.style.maxHeight = window.innerHeight + 'px';
            doc.style.maxWidth = window.innerWidth + 'px';
            container.style.maxHeight = (window.innerHeight - 70) + 'px';
            container.style.height = (window.innerHeight - 70) + 'px';
        }

        init();

    }, []);

    return (
        <Stack tokens={outerStackTokens} verticalFill id='body-container'>
            {loginToApp && <BrowserRouter>
                <Routes>
                    <Route path="/settings" element={<AutentiSettings/>}/>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/close" element={<Close/>}/>
                    <Route path="/SharePoint" element={<SharePoint/>}/>
                </Routes>
            </BrowserRouter> }
        </Stack>

    );
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState<any>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}
