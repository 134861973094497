import {IImageProps, Image, Stack} from '@fluentui/react';
import {useEffect, useState} from 'react';
import Moment from 'moment';
import Classic_Sign_ico from '../icons/Classic_Sign.svg';
import Success_ico from '../icons/Success.svg';
import Delete_ico from '../icons/Delete.svg';
import Withdraw_ico from '../icons/Withdraw.svg';
import Eye_ico from '../icons/Eye.svg';
import {useTranslation} from 'react-i18next';
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";

function History(props: any) {

    const {t, i18n} = useTranslation();
    const [events, setEvents] = useState<any>([]);
    const [documentId, setDocumentId] = useState(props.id);

    const fetchData = async (id: string) => {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        return await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + id + '/parties', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken
                // 'Authorization': 'Bearer ' + process.env.REACT_APP_API_AUTENTI
            },
        })
            .then(response => response.json())
            .then(json => {
                var history = json.map(function (party: any) {
                    return makeHistory(party.events, party.party.name)
                })
                var new_history: any[] = [];
                history.map((item: any, index: number) => {
                    item.map((child_item: any) => {
                        new_history.push(child_item);
                    })
                })
                var filtered = new_history.filter(function (el: any) {
                    return el != null;
                });
                setEvents(filtered);
            });
    }

    useEffect(() => {
        fetchData(props.id);
        setEvents([]);
    }, [])

    if (props.id !== documentId) {
        setDocumentId(props.id);
        fetchData(props.id);
    }

    function makeHistory(events: any, name: string) {
        var history: { timestamp: any; name: string; event: string | undefined; icon: string | undefined; }[] = [];
        events.map(function (event: any) {
            var evType = getEvent(event.eventType);
            history.push({
                'timestamp': event.timestamp,
                'name': name,
                'event': evType?.text,
                'icon': evType?.icon
            });
        });
        return history;
    }

// do helperów
    function getEvent(event: any) {
        const event_type = event.split('EVENT_CLASSIFIER-UNIQUE_TYPE:')[1];
        switch (event_type) {
            case 'SIGNATURE_APPLICATION':
                return {
                    text: t('DocumentDetails.historySigned'),
                    icon: Classic_Sign_ico
                }
            case 'SIGNATURE_REJECTION':
                return {
                    text: t('DocumentDetails.historyRejected'),
                    icon: Delete_ico
                }
            case 'APPROVAL_CONSENT':
                return {
                    text: t('DocumentDetails.approvalCompletedWithConsent'),
                    icon: Success_ico
                }
            case 'APPROVAL_REJECTION':
                return {
                    text: t('DocumentDetails.approvalCompletedWithRejection'),
                    icon: Delete_ico
                }
            case 'REVIEW_POSITIVE':
                return {
                    text: t('DocumentDetails.detailsDecisionResultReviewPositive'),
                    icon: Success_ico
                }
            case 'REVIEW_NEGATIVE':
                return {
                    text: t('DocumentDetails.detailsDecisionResultReviewNegative'),
                    icon: Delete_ico
                }
            case 'DOCUMENT_DRAFT_CREATED':
                return {
                    text: t('DocumentsList.draftVersion'),
                    icon: Classic_Sign_ico
                }
            case 'DOCUMENT_SENT':
                return {
                    text: t('DocumentDetails.sent'),
                    icon: Classic_Sign_ico
                }
            case 'DOCUMENT_VIEW':
                return {
                    text: t('DocumentDetails.historyViewed'),
                    icon: Eye_ico
                }
            case 'DOCUMENT_WITHDRAWAL':
                return {
                    text: t('DocumentDetails.historyWithdrawn'),
                    icon: Withdraw_ico
                }
            case 'DOCUMENT_ARCHIVIZATION':
                return {
                    text: 'Document Archived',
                    icon: ''
                }
            case 'DOCUMENT_DEARCHIVIZATION':
                return {
                    text: 'Document dearchived',
                    icon: ''
                }
            case 'DOCUMENT_PROCESS_COMPLETED':
                return {
                    text: t('DocumentsList.signed'),
                    icon: Success_ico
                }
        }
    }

    return (
        <Stack className='history-container'>
            {events.map((item: any, i: any) => {
                return (
                    <Stack horizontal className='history-element' key={i}>
                        <Stack.Item>
                            <div className='file-ico'><Image src={item.icon} className='history-icon'/></div>
                        </Stack.Item>
                        <Stack.Item className='history-item'>
                            <Stack>
                                <Stack.Item className='history-event'>{item.event}</Stack.Item>
                                <Stack.Item><span className='history-name'>{item.name}</span> <span
                                    className='history-timestamp'>{Moment(item.timestamp).format('DD-MM-YYYY, HH:mm')}</span></Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                );
            })}
        </Stack>
    );

};

export default History
