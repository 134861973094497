import { Stack, IStackStyles, IStackItemStyles, DefaultButton, Persona, PersonaPresence, PersonaSize, IIconProps } from '@fluentui/react';
import React, { useState } from 'react';
import Company_ico from '../icons/Company.svg';
import User_ico from '../icons/User.svg';
import up_ico from '../icons/up.svg';
import down_ico from '../icons/down.svg';
import { useTranslation } from 'react-i18next';


const stackStyles: IStackStyles = {
    root: {
        padding: 0
    },
};
const stackItemStyles: IStackItemStyles = {
    root: {
        padding: 10
    },
};
const upIcon: IIconProps = { imageProps: { src: up_ico } };
const downIcon: IIconProps = { imageProps: { src: down_ico } };

function Recipients(props: any) {
    const [showDetails, setShowDetails] = useState(false)
    const { t, i18n } = useTranslation();
    const [renderDetails, updateRenderDetails] = useState<boolean>(true);
    const _showDetails = () => {
        setShowDetails(showDetails ? false : true);
    }

    return (
        <Stack.Item>
            <Stack styles={stackStyles} horizontal className="documet-recipients-container" wrap>
                <Stack.Item grow styles={stackItemStyles}>
                    <Persona
                        {...{
                            imageUrl: props.recipient.party.relationships.length === 0 ? User_ico : Company_ico,
                            imageInitials: props.recipient.party.firstName.split('')[0] + props.recipient.party.lastName.split('')[0],
                            text: props.recipient.party.name,
                            secondaryText: props.recipient.party.contacts[0].attributes.email,
                        }}
                        size={PersonaSize.size40}
                        hidePersonaDetails={!renderDetails}
                        presence={PersonaPresence.none}
                        imageAlt=""
                    />
                </Stack.Item>
                <Stack.Item className="recipients-desc">
                    <div>
                        {
                            (props.recipient.party.relationships.length === 0)
                                ? <span>{t('Settings.naturalReprezentation')}</span>
                                : <span>{t('Settings.orgReprezentation2')} <b>{props.recipient.party.relationships[0].party.name}</b></span>
                        }

                    </div>
                    <div>
                        {props.recipient.participationStatus === 'PENDING' &&
                            <span className="waiting">{t('DocumentDetails.detailsDecisionResultToDecide')}</span>
                        }
                        {props.recipient.participationStatus === 'COMPLETED' &&
                            <span className="signed">{t('DocumentDetails.detailsDecisionResultSigned')}</span>
                        }
                        {props.recipient.status === 'REJECTED' &&
                            <span className="rejected">{t('DocumentDetails.detailsDecisionResultRejected')}</span>
                        }
                    </div>
                    {showDetails}
                </Stack.Item>
                <Stack.Item className='details-button-container'>
                    {
                        (props.recipient.party.relationships.length === 0)
                            ? null
                            : <DefaultButton onClick={_showDetails} iconProps={showDetails ? upIcon : downIcon} className='recipients-details' />
                    }
                </Stack.Item>
            </Stack>

            <Stack>
                {showDetails ?
                    <Stack.Item className='recipient-details-container'>
                        <Stack className='recipient-details-border'></Stack>
                        <Stack>
                            <Stack.Item className='recipient-details-text'>
                                <span>{t('Settings.orgReprezentationInfo2')}</span>
                            </Stack.Item>
                            {props.recipient.party.relationships.map((relationship: any, i: any) => {
                                return (
                                    <Stack.Item key={i}>
                                        <Stack horizontal>
                                            <Stack.Item><span>{t('Settings.companysname')} </span><span className='recipient-details-bold'>{relationship.party.name}</span></Stack.Item>
                                            <Stack.Item><span>{t('Settings.nipPl')} </span><span className='recipient-details-bold'>{relationship.party.extIds[0].identifier}</span></Stack.Item>
                                            {relationship.attributes.relationshipDescription &&
                                                <Stack.Item><span>{t('Settings.position')} </span><span className='recipient-details-bold'>{relationship.attributes.relationshipDescription}</span></Stack.Item>}
                                        </Stack>
                                    </Stack.Item>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                    : null}
            </Stack>

        </Stack.Item>
    );
}
export default Recipients