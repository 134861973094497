import React, {useEffect, useState} from 'react';
import {
    Stack, IStackStyles, IStackTokens, IStackItemStyles, DefaultPalette,
    initializeIcons,
    DefaultButton
} from '@fluentui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import jwt_decode from 'jwt-decode';
import '../App.css';
import Inbox from './Inbox';
import DocumentPreview from './DocumentPreview';
import Topbar from './Topbar';
import {useTranslation} from 'react-i18next';
import Fault from './Fault';
import {toQueryString, getHashParameters, _guid} from './helpers/ms-helpers';
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";
import {app, authentication} from "@microsoft/teams-js";
import Context = app.Context;

initializeIcons();

// Tokens definition
const outerStackTokens: IStackTokens = {
    childrenGap: 0,
    maxHeight: '100%'
};
const innerStackTokens: IStackTokens = {
    childrenGap: 0,
    padding: 0,
};
const stackTokens: IStackTokens = {
    childrenGap: 0,
    padding: 0,
};
const stackStylesHorizontal: IStackStyles = {
    root: {
        background: '#fffff',
        // maxHeight: 1000
    },
};
const stackItemStylesHorizontal: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: "#ffffff",
        color: DefaultPalette.black,
        display: 'block',
        maxHeight: '100%',
        padding: 0
        // justifyContent: 'center',
    },
};
const spinnerStyle: IStackItemStyles = {
    root: {
        padding: 16
    },
};

export const Home: React.FunctionComponent = () => {
    const {t, i18n} = useTranslation();

    const initteams = app.initialize().catch(e => {
        console.log(e)
    })

    const [token, setToken] = useState<string>(localStorage.getItem('token') || '');
    const [paginatStep, setPaginateStep] = useState<number>(22);
    const [searchQuery, setSearchQuery] = useState<any>();
    const [settings, setSettings] = useState<boolean>(false);
    const [drafts, setDrafts] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [mistake, setMistake] = useState<boolean>(false);
    const [id, setId] = useState('');
    const [lsatSelectedId, setLastSelectedId] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(false);
    const [documentView, setDocumentView] = useState(false);
    const [adminConset, setAdminConsent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>();
    const size = useWindowSize();


    let setting: any = false;

    // Krok pierwszy dla admin consent
    async function promptForConsent(callback: any) {
        // Cause Teams to popup a window for consent
        localStorage.setItem("consent_state", 'start');
        await authentication.authenticate({
            url: `${window.location.origin}/auth_start`,
            width: 600,
            height: 535,
            successCallback: (result:string ) => {
                callback(null);
            },
            failureCallback: (error: string) => {
                console.log(error);
                callback(error);
            }
        });
    }

    // Krok drugi dla admin consent
    function stepTwoForConsent() {
        app.getContext().then((context: Context) => {
            // Generate random state string and store it, so we can verify it in the callback
            let state = _guid(); // _guid() is a helper function in the sample
            localStorage.setItem("auth-state", state);
            localStorage.removeItem("auth-error");
            // Go to the Azure AD authorization endpoint
            let queryParams = {
                client_id: localStorage.getItem('aud'), //AAD Cient ID
                response_type: "token",
                response_mode: "fragment",
                prompt: 'consent',
                scope: "https://graph.microsoft.com/.default",
                redirect_uri: window.location.origin + "/auth_end",
                nonce: _guid(),
                state: state,
                // The context object is populated by Teams; the loginHint attribute
                // is used as hinting information
                login_hint: context.user?.loginHint,
            };

            let authorizeEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?" + toQueryString(queryParams);
            window.location.assign(authorizeEndpoint);

        });
    }

    // Ostatni krok dla admin consent
    function authCompleteForConsent() {

        const hashParams: any = getHashParameters();
        if (hashParams['error']) {
            authentication.notifyFailure(hashParams['error']);
        } else if (hashParams['access_token']) {
            // Check the state parameter
            const expectedState = localStorage.getItem('auth-state');
            if (expectedState !== hashParams['state']) {
                authentication.notifyFailure('StateDoesNotMatch');
            } else {
                // State parameter matches, report success
                // localStorage.setItem("consent_state", 'end');
                localStorage.removeItem('auth-state');

                authentication.notifySuccess('Success');
                // preInit();
            }
        } else {
            authentication.notifyFailure('NoTokenInResponse');
        }
        app.openLink('https://teams.microsoft.com/l/entity/' + process.env.REACT_APP_TEAMS_APP_ID + '/autenti_settings');
        localStorage.setItem("consent_state", 'end');
        setAdminConsent(true);

        // window close?
    }

    // initialize function
    const init = async (): Promise<Record<string, number | string>> => {
        if ((/close/i).test(window.location.href)) {
            window.close();
        }
        // Parametry w linku
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get("loginsuccess")) {
            // window.close()
            if (localStorage.getItem('login_state') === null) {
                const encodedContext = encodeURI('{"subEntityId": "loggedin"}');
                window.location.replace(`https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/autenti_inbox?webUrl=${encodeURI(window.location.origin + '/loggedin')}&context=${encodedContext}`);
            } else {
                localStorage.clear();
                localStorage.setItem("login_state", "done");
                window.close()
            }
        }

        if ((/auth_start/i).test(window.location.href)) {
            stepTwoForConsent();
        }

        if ((/auth_end/i).test(window.location.href)) {
            authCompleteForConsent();
        }
        if (localStorage.getItem('version') !== 'v4') {
            localStorage.clear();
        }

        setLoading(true);
        let teams = true;
        try {
            app.getContext().then((settings: Context) => {
                if (settings.page.subPageId === "loggedin") {
                    localStorage.clear();
                    localStorage.setItem("login_state", "loggedin");
                    // window.location.replace(window.location.origin);
                }

                if (settings.user?.userPrincipalName !== localStorage.getItem('userPrincipalName')) {
                    localStorage.clear();
                }
                localStorage.setItem('userPrincipalName', settings.user?.userPrincipalName || ' ');
                localStorage.setItem('version', 'v4');
                i18n.changeLanguage(settings.app.locale);
            });
        } catch (e) {
            console.log(e)
        }

        // true dla karty Settings
        const tab: string = urlParams.get('tab') || 'false';


        setSettings((/settings/i).test(tab));
        setting = (/settings/i).test(tab)
        setDrafts((/drafts/i).test(tab));

        if ((localStorage.getItem('cookie_name') === 'logout' && !setting)) {
            try {
                app.openLink('https://teams.microsoft.com/l/entity/' + process.env.REACT_APP_TEAMS_APP_ID + '/autenti_settings');
            } catch (e) {
                console.log(e)
            }
        }
        if (localStorage.getItem('init') === null) {
            localStorage.setItem('init', 'true');
        }
        if (!setting) {
            try {
                // const awaitToken = await refreshToken();
            } catch (e) {
                console.log(e)
            }

        }
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        setToken(getToken);
        setLoading(false);
        return setting;
    }

    const _fault = (isSign: boolean) => {
        setMistake(isSign)
    }

    const _setDocId = (doc_id: any) => {
        setId(doc_id);
        setLastSelectedId(true);
        if (listView === true) {
            setListView(false);
            setDocumentView(true);
        }
    }

    const _searchQuery = (query: any) => {
        setSearchQuery(query);
    }

    const _reload = () => {
        setReload(reload ? false : true);
    }

    const _back = () => {
        setListView(true);
        setDocumentView(false);
    }

    const setFault = () => {
        setMistake(true);
        setSettings(false);
    }
    const setErrorMsg = (msg: any) => {
        setErrorMessage(msg);
    }
    const goToSettings = () => {
        window.location.reload();
    }
    const handleScroll = (e: { target: any; }) => {
        let element: any = e.target
        if (Math.ceil(element.scrollHeight - element.scrollTop) === element.clientHeight) {
            setPaginateStep(paginatStep => (paginatStep + 20))
        }

    }

    useEffect(() => {
        if (size.width <= 1024) {
            setListView(true)
        }
        let doc: any = document.getElementById('body-container') || document.createElement('div');
        let container: any = document.getElementById('container') || document.createElement('div');
        if (doc && container) {
            doc.style.height = window.innerHeight + 'px';
            doc.style.maxHeight = window.innerHeight + 'px';
            doc.style.maxWidth = window.innerWidth + 'px';
            container.style.maxHeight = (window.innerHeight - 70) + 'px';
            container.style.height = (window.innerHeight - 70) + 'px';
        }

        init();

    }, [token]);

    return (
        <>
            {(!settings && !mistake) &&
                <Stack tokens={innerStackTokens} verticalAlign="center">
                    <Stack.Item grow className="top-bar">
                        <Topbar setQuery={_searchQuery} documentView={documentView} back={_back} setPaginateStep={setPaginateStep}></Topbar>
                    </Stack.Item>
                    <Stack.Item grow={14} id='container'>
                        {(token !== '') ?
                            <Stack grow horizontal styles={stackStylesHorizontal} tokens={stackTokens} verticalFill>
                                {!documentView &&
                                    <Stack.Item grow className="border-right inbox" verticalFill
                                                onScroll={handleScroll}>
                                        <Inbox reload={_reload} doReload={reload}
                                               setDocId={_setDocId} step={paginatStep} searchQuery={searchQuery}
                                               lastId={lsatSelectedId} documentView={documentView}
                                               listView={listView}
                                               drafts={drafts}></Inbox>
                                    </Stack.Item>
                                }
                                {!listView &&
                                    <Stack.Item grow={25} styles={stackItemStylesHorizontal} verticalFill>
                                        {id ? <DocumentPreview reload={_reload} id={id}
                                                               fault={_fault} promptForConsent={promptForConsent}
                                                               drafts={drafts}></DocumentPreview> : null}
                                    </Stack.Item>
                                }
                            </Stack>
                            :
                            <Stack grow horizontalAlign='center'>
                                <h5>{t('Board.listEmpty')}</h5>
                                <Stack.Item grow><DefaultButton
                                    onClick={goToSettings}>{t('BottomNavigationComponent.settingsLink')}</DefaultButton></Stack.Item>
                            </Stack>
                        }
                        {loading && <Stack grow styles={spinnerStyle}><Spinner size={SpinnerSize.large}/></Stack>}
                    </Stack.Item>

                </Stack>
            }
            {(mistake && !settings) && <Fault errorMessage={errorMessage}></Fault>}
        </>
    );
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState<any>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}
