const aadAppId : string = process.env.REACT_APP_AAD_APP_ID ?? '';
const aadRedirectUri : string = process.env.REACT_APP_AAD_REDIRECT_URI ?? '';
const aadScopes : string = process.env.REACT_APP_AAD_SCOPES ?? '';
export const msalConfig = {
    auth: {
        clientId: aadAppId,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: aadRedirectUri,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};
export const loginRequest = {
    scopes: [aadScopes]
};
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
