import {
    Stack, DayOfWeek, DatePicker, defaultDatePickerStrings, ChoiceGroup, IChoiceGroupOption, TextField, IStackItemStyles, DefaultButton
} from '@fluentui/react';
import React, { FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const stackItemStyles: IStackItemStyles = {
    root: {
        padding: 5,
    },
};

function Filters(props: any) {
    const { t, i18n } = useTranslation();
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(DayOfWeek.Sunday);
    const [modifiedAfter, setModifiedAfter] = useState<Date | undefined>();
    const [modifiedBefore, setModifiedBefore] = useState<Date | undefined>();
    const [company_name, setCompany_name] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [first_name, setFirst_name] = useState<string>('');
    const [last_name, setLast_name] = useState<string>('');
    const [search_by, setSearch_by] = useState<string>('sender');
    function _onChange(ev?: FormEvent<HTMLInputElement | HTMLElement> | undefined, option?: IChoiceGroupOption): void {
        setSearch_by(option?.key || 'sender')
    }
    const options: IChoiceGroupOption[] = [
        { key: 'sender', text: t('DocumentsListSearch.documentsSenderRadio') },
        { key: 'recipient', text: t('DocumentsListSearch.documentsReceiverRadio') },
    ];
    function searchQuery() {
        var query ={};
        if(search_by === 'sender') {
             query = {
                'createdAfter': modifiedAfter?.toISOString() !== undefined ? modifiedAfter?.toISOString() : '',
                'createdBefore': modifiedBefore?.toISOString() !== undefined ? modifiedBefore?.toISOString() : '',
                'senderCompanyName': company_name,
                'senderEmail': email,
                'senderFirstName': first_name,
                'senderLastName': last_name,
            };
        } else {
             query = {
                'createdAfter': modifiedAfter?.toISOString(),
                'createdBefore': modifiedBefore?.toISOString(),
                'recipientCompanyName': company_name,
                'recipientEmail': email,
                'recipientFirstName': first_name,
                'recipientLastName': last_name,
            };
        }

        props.query(query);
    }

    const onChangeCompany = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setCompany_name(newValue || '');
        },
        [],
    );
    const onChangeEmail = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setEmail(newValue || '');
        },
        [],
    );
    const onChangeFirst_name = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setFirst_name(newValue || '');
        },
        [],
    );
    const onChangeLast_name = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setLast_name(newValue || '');
        },
        [],
    );
    return (
        <Stack>
            <Stack.Item grow >
                <Stack horizontal>
                    <Stack.Item grow styles={stackItemStyles}>
                        <DatePicker value={modifiedAfter}
                            onSelectDate={setModifiedAfter as (date: Date | null | undefined) => void}
                            label={t('DocumentsListSearch.dateFrom')}
                            firstDayOfWeek={firstDayOfWeek}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            strings={defaultDatePickerStrings}
                        />
                    </Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}>
                        <DatePicker value={modifiedBefore}
                            onSelectDate={setModifiedBefore as (date: Date | null | undefined) => void}
                            label={t('DocumentsListSearch.dateTo')}
                            firstDayOfWeek={firstDayOfWeek}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            strings={defaultDatePickerStrings}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item grow styles={stackItemStyles}>
                <ChoiceGroup styles={{ flexContainer: { display: "flex", padding: 5 } }} defaultSelectedKey="sender" options={options} onChange={_onChange} label={t('DocumentsListSearch.sharedDocumentsSearchIn')} />
            </Stack.Item>
            <Stack.Item grow styles={stackItemStyles}>
                <TextField label={t('DocumentsListSearch.companyName')} value={company_name} onChange={onChangeCompany} />
            </Stack.Item>
            <Stack.Item grow styles={stackItemStyles}>
                <TextField label={t('DocumentsListSearch.email')} value={email} onChange={onChangeEmail} />
            </Stack.Item>
            <Stack.Item grow>
                <Stack horizontal grow>
                    <Stack.Item grow styles={stackItemStyles}><TextField label={t('DocumentsListSearch.firstName')} value={first_name} onChange={onChangeFirst_name} /></Stack.Item>
                    <Stack.Item grow styles={stackItemStyles}><TextField label={t('DocumentsListSearch.lastName')} value={last_name} onChange={onChangeLast_name} /></Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item grow styles={stackItemStyles}>
                <DefaultButton text={t('DocumentsListSearch.search')} onClick={searchQuery} className='searh-button' />
            </Stack.Item>
        </Stack>
    );
}
export default Filters