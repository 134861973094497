import React, {useEffect, useState} from "react";
import {
    Stack,
    Image,
    ImageFit,
    DefaultButton,
    IStackItemStyles,
} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import Sign_svg from "../icons/Podpisywanie.svg";
import "../styles/Settings.css";
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';
import Login from "./Login";
import {app} from "@microsoft/teams-js";

const loginContainer: IStackItemStyles = {
    root: {
        padding: 16
    },
};
const spinerWrpper: IStackItemStyles = {
    root: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 10
    },
};
const bottomText: IStackItemStyles = {
    root: {
        marginTop: 20,
        paddingTop: 20
    },
};
type Loadings = {
    checkAdmin: boolean,
    connect: boolean
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
function Settings(props: any) {
    app.initialize().catch(e => {
        console.log(e)
    })

    const {t, i18n} = useTranslation();
    const [notAdminFailure, setNotAdminFailure] = useState<boolean>(false);
    const [update, setisUpdate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [connectionSuccess, setConnectionSuccess] = useState<boolean>(false);
    const [loginState, setLoginState] = useState<boolean>(false);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const procescode: string = urlParams.get("processcode") || "";
    const [loadinButton, setLoadingButton] = useState<Loadings>({checkAdmin: false, connect: false});


    localStorage.setItem('query', queryString);

    useEffect(() => {
        if (update) {
            setLoadingButton({...loadinButton, connect: false});
            setLoading(false);
        }
    }, [update]);



    return (
        <Stack grow styles={loginContainer} wrap>
            <Stack verticalAlign="center" grow className="container" wrap>

                <Stack.Item align="center" className="container">
                    {
                        connectionSuccess ?
                            <Stack grow horizontalAlign='center' horizontal wrap>
                                {(notAdminFailure && !loading) ?
                                    <Stack>
                                        <Stack.Item
                                            className="settings-text-title">{t('Settings.YourAutentiAccountDoesNotHave')}</Stack.Item>
                                        <Stack.Item styles={loginContainer}
                                                    className="settings-text"><DefaultButton
                                            text={t('Settings.CloseWindow')}
                                            onClick={() => window.close()}/>
                                        </Stack.Item>
                                    </Stack>
                                    :
                                    <Stack>
                                        {!loading &&
                                            <Stack>
                                                <Stack.Item
                                                    className="settings-text-title">{t('Settings.ConnectionSucces')}</Stack.Item>
                                                <Stack.Item
                                                    className="settings-text">{t('Settings.ConnectionSuccesDesc')}</Stack.Item>
                                            </Stack>
                                        }
                                    </Stack>

                                }
                                {loading &&
                                    <Stack>
                                        <Stack.Item>
                                            <Spinner size={SpinnerSize.large}/></Stack.Item>
                                    </Stack>
                                }
                            </Stack>
                            :
                            <Stack className="ident" grow horizontal wrap>


                                    <Stack grow horizontal horizontalAlign="center" wrap>
                                        <Stack.Item grow verticalFill  className="image-wrapper">
                                            <Image src={Sign_svg} imageFit={ImageFit.center} width={328}
                                                   height={304}/>
                                        </Stack.Item>
                                        <Stack.Item grow verticalFill>
                                            <Stack>
                                                 <Login uptate={update} loginState={setLoginState}></Login>
                                            </Stack>
                                        </Stack.Item>

                                    </Stack>
                            </Stack>
                    }
                </Stack.Item>
            </Stack>

        </Stack>
    );
}

export default Settings;
