import React from 'react';
import {Stack, Image, ImageFit} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import Alert_svg from '../icons/Alert.svg';
import Email_svg from '../icons/Email.svg';
import Phone_svg from '../icons/Phone.svg';
import '../styles/Fault.css';

function Fault(props: any) {

    const { t, i18n } = useTranslation();

    return (
        <Stack verticalAlign='center' grow className='container'>
                <Stack.Item align="center">
                        <Stack grow horizontalAlign='center'>
                            <Stack.Item><Image src={Alert_svg} imageFit={ImageFit.center} width={64} height={64} className="fault-icon" /></Stack.Item>
                            <Stack.Item className="fault-text-title">{t('GlobalError.generalErrorBody')}</Stack.Item>
                            <Stack.Item className="fault-text">{t('GlobalError.errorCodeInfo')}</Stack.Item>
                            <Stack.Item className="fault-info">
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack.Item>
                                        <Stack.Item><Image src={Email_svg} imageFit={ImageFit.center} width={20} height={16} className="fault-email-icon" /></Stack.Item>
                                        <Stack.Item className="fault-text-subtitle">{t('Error.UnknownErrorEmail')}</Stack.Item>
                                        <Stack.Item className="fault-email">support@autenti.com</Stack.Item>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack.Item><Image src={Phone_svg} imageFit={ImageFit.center} width={18} height={18} className="fault-email-icon" /></Stack.Item>
                                        <Stack.Item className="fault-text-subtitle">Hotline (8:00am-18:00pm)</Stack.Item>
                                        <Stack.Item className="fault-email">+48 22 29 05 112</Stack.Item>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <span>We work Mon-Fri , 8:00-18:00</span>
                            </Stack.Item>
                            <Stack.Item>
                                {props.errorMessage && <div>{props.errorMessage.toString()}</div>}
                            </Stack.Item>
                        </Stack>
                </Stack.Item>

        </Stack>
    );
}
export default Fault