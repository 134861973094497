import {
    Stack,
    IStackItemStyles,
    DefaultButton,
    Pivot,
    PivotItem,
    Image,
    Modal,
    IPersonaSharedProps,
    ImageFit,
    IconButton,
    IButtonStyles,
    IIconProps,
    IImageProps,
    IStackStyles, DefaultPalette
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import Moment from 'moment';
import History from './History';
import FileList from './FileList';
import '../styles/Document.css';
import Progressbar from './Progressbar';
import Company_ico from '../icons/Company.svg';
import User_ico from '../icons/User.svg';
import Withdraw_ico from '../icons/Withdraw.svg';
import {Spinner, SpinnerSize} from '@fluentui/react/lib/Spinner';
import Archive_ico from '../icons/Archive.svg';
import {useTranslation} from 'react-i18next';
import {useBoolean, useId} from '@fluentui/react-hooks';
import Sign from './Sign';
import {getMessage, getStatus} from './helpers/document-helper';
import DocumentDetails from './DocumentDetails';
import {Base64} from 'js-base64';
import OtpHelper from "./helpers/otp/OtpHelper";
import HeadersHelper from "./helpers/otp/HeadersHelper";
import {AutentiToken, TeamsMsToken} from "./helpers/tokens/TokensHelper";

// draft - uczestnicy tak | pasek postępu

const stackItemStyles: IStackItemStyles = {
    root: {
        padding: 10
    },
};
const stackChildItemStyles: IStackItemStyles = {
    root: {
        paddingLeft: 10,
        paddingRight: 10
    },
};
const pivotStackItem: IStackItemStyles = {
    root: {
        marginTop: 0
    },
};
const pivotContainerStackItem: IStackItemStyles = {
    root: {
        padding: 0
    },
};
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
};
const stackStyles: IStackStyles = {
    root: {
        height: '100%',
        minHeight: '100%'
    },
};
type NextChallenge = {
    firstChallengeToResponse?: any[],
    secondChallengeToResponse?: any[],
    thirdChallengeToResponse?: any[],
    fourthChallengeToResponse?: any[]
}
const cancelIcon: IIconProps = {iconName: 'Cancel'};

function DocumentPreview(props: any) {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [documentItem, setDocumentItem] = useState<any>([]);
    const [documentId, setDocumentId] = useState(props.id);
    const [sender, setSender] = useState<IPersonaSharedProps>();
    const [recipients, setRecipients] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);
    const [signUri, setSignUri] = useState<string>('');
    const [signatureId, setSinaturteId] = useState<string>('');
    const [successSave, setSuccessSave] = useState<boolean>(false);
    const [otpChallange, setOtpChallange] = useState<any[]>();
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [isignModalOpen, {setTrue: showSignModal, setFalse: hideSignModal}] = useBoolean(false);
    const [signActive, setSignActive] = useState<boolean>(true);
    const [signActionSuccess, setSignActionSuccess] = useState<boolean>(false);
    const [getNextChallenge, setGetNextChallenge] = useState<NextChallenge>();
    const [otpStep, setotpStep] = useState<number>(0);
    const titleId = useId('title');
    const titleSignId = useId('title');
    const [isInArchive, setIsInArchive] = useState<boolean>(false);
    const createUrl = process.env.REACT_APP_CREATE_LOGIN_BASE_URL + '/app/login?accessTokenCookieName=' + localStorage.getItem('cookie_name') + '&useLoggedInUser=true&successUrl=' + process.env.REACT_APP_CREATE_REDIRECT_DOMAIN;

    useEffect(() => {
        setDocumentId(props.id)
        fetchData(props.id);
        setSuccessSave(false);
    }, [props.id, getNextChallenge]);


    const fetchData = async (id: string) => {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        let headers;
        if (getNextChallenge) {
            headers = HeadersHelper(otpStep, id, getToken, getNextChallenge);
            setotpStep(otpStep + 1)
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken,
            }
        }
        const requestHeaders = new Headers(headers);
        return await fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + id, {
            method: 'GET',
            headers: requestHeaders,
        })
            .then(response => {
                if (response.status === 403) {
                    console.log('otpStep', otpStep)
                    const headers = response.headers.get('x-challenge');
                    const xChallenge = headers?.split(',');
                    let decoded;
                    const xChallengeDecoded = xChallenge?.map((challenge: any, i: any) => {
                        decoded = Base64.decode(challenge);
                        return JSON.parse(decoded);
                    })
                    if (otpStep === 0) {
                        setotpStep(1)
                    }
                    setOtpChallange(xChallengeDecoded);
                } else {
                    setotpStep(0)
                    return response.json()
                }
            })
            .then(json => {
                setDocumentItem(json);
                setLoading(false);
                let person = json.parties.filter((item: { role: string | string[]; }) => item.role.indexOf('SENDER') >= 0)[0];
                let persons = json.parties.filter((item: { role: string | string[]; }) => item.role.indexOf('SIGNER') >= 0);
                let _imageUrl = '';
                if (person.party.relationships.length === 0) {
                    _imageUrl = User_ico;
                } else {
                    _imageUrl = Company_ico;
                }
                let newPersona = {
                    imageUrl: _imageUrl,
                    imageInitials: person.party.firstName.split('')[0] + person.party.lastName.split('')[0],
                    text: person.party.name,
                    secondaryText: person.party.contacts[0].attributes.email,
                    tertiaryText: person.party.relationships[0].party.name,
                    optionalText: person.party.relationships[0].party.extIds[0].identifier
                }
                setRecipients(persons);
                setSender(newPersona);
                if(json.flags.indexOf('FLAG:ARCHIVED') === -1){
                    setIsInArchive(false)
                } else {
                    setIsInArchive(true)
                }
                if (!props.drafts) {
                    getActive();
                }
            })
    }


    if (loading) {
        return (
            <Spinner size={SpinnerSize.large}/>
        );
    }

    async function getActive() {
        setSignActive(active => (active = true));
        let signActions: any = await signDocument()
        for (let i = 0; i < signActions.attributes.options.length; i++) {
            if ((/SIGNATURE_APPLICATION/i).test(signActions.attributes.options[i].id)) {
                setSignActive(active => (active = false));
                setSinaturteId(signActions.attributes.options[i].id);
                break;
            } else {
                setSignActive(active => (active = true));
            }
            if (signActions.attributes.options.length === 0) {
                setSignActive(active => (active = true));
            }
        }

    }

    function signDocument() {
        return new Promise(async resolve => {
                    const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
            fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + props.id + '/actions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + getToken,
                    'access-control-allow-headers': '*',
                    'access-control-expose-headers': '*',
                    'Access-Control-Allow-Origin': '*',
                    'Accept-Language': i18n.language
                },
            })
                .then(function (response) {
                    if (response.status === 403) {
                        let xChallange: any = response.headers.get('x-challenge') || '';
                        let decoded: any = Base64.decode(xChallange);
                        let obj = JSON.parse(decoded);
                        setActions(obj);
                        resolve(obj);
                        return true
                        // showDialog()
                    }
                })
        })
    }


    async function sendAction(action: any) {
                const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        let toSend: object = {
            'classifiers': ["CHALLENGE_CLASSIFIER-UNIQUE_TYPE:ACTION_SELECTION"],
            "attributes": {
                "selectedIds": [action]
            }
        }
        let encoded = Base64.encodeURI(JSON.stringify(toSend));

        fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + props.id + '/actions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken,
                'access-control-allow-headers': '*',
                'access-control-expose-headers': '*',
                'X-ASSERTION': encoded,

            },
        })
            .then(function (response) {
                let xChallange: any = response.headers.get('x-challenge') || '';
                let decoded: any = Base64.decode(xChallange);
                let obj = JSON.parse(decoded);
                let uri = '';
                const windowLocation = encodeURI(window.location.protocol + '//' + window.location.host + '/close')
                try {
                    uri = obj.attributes.httpRequest.uri;
                    setSignUri(signUri => (signUri = uri));
                    const link = document.createElement('a');
                    link.href = uri + '&returnUrl=' + windowLocation;
                    link.target = '_blank';
                    link.click();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);

                } catch (error) {
                    if (error) {
                        console.log(error)
                        setSignActionSuccess(true);
                    }
                }

            }).catch(error => {
            console.log(error);
        });


    }

    async function Archive() {
                const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + props.id + '/flags/FLAG:ARCHIVED', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => response)
            .then(json => {
                props.reload(true);
            }).catch(error => {
            console.log(error);
        });
        props.reload(true);
        window.location.reload();
    }
    async function Unarchive() {
        const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        fetch(process.env.REACT_APP_API_AUTENTI + '/api/v2/document-processes/' + props.id + '/flags/FLAG:ARCHIVED', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + getToken
            },
        })
            .then(response => response)
            .then(json => {
                props.reload(true);
            }).catch(error => {
            console.log(error);
        });
        props.reload(true);
        window.location.reload();
    }

    function SaveToOneDrive(id: string, filename: string) {
        setSuccessSave(true);
        fetch('/api/Autenti/save-to-onedrive?documentId=' + encodeURIComponent(props.id) + '&id=' + encodeURIComponent(id) + '&fileName=' + encodeURIComponent(filename), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('msToken')
            },
        })
            .then(resp => {
                return resp.ok
            }).then((ok) => {
            setSuccessSave(true);
        });
        setSuccessSave(successSave => (successSave = true));
        return true
    }

    const imageWithdrawal: IImageProps = {
        src: Withdraw_ico,
        imageFit: ImageFit.center,
        width: 17,
        height: 17
    };
    const withdrawalIcon: IIconProps = {
        imageProps: imageWithdrawal
    };
    const imageArchive: IImageProps = {
        src: Archive_ico,
        imageFit: ImageFit.center,
        width: 17,
        height: 17
    };
    const archiveIcon: IIconProps = {
        imageProps: imageArchive
    };

    async function getJwtRedirectToken(draftId: string) {
                const msToken = await TeamsMsToken();
        const getToken = await AutentiToken(msToken);
        const cookieName = localStorage.getItem('cookie_name') || ''
        const windowLocation = encodeURI(window.location.protocol + '//' + window.location.host + '/close')

        let response = await fetch('/api/token/encode?cookieName=' + cookieName + '&redirectUrl=' + createUrl + '/' + draftId + '?returnTo=' + windowLocation, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ` + getToken
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(json => {
            const redirect = window.location.origin + '/api/token/decode?token=' + json.token
            window.open(redirect, '_blank');
            // runTeamsTask(redirect);
        }).catch(error => {
            console.log('error', error)
        })
    }

    if (documentItem) {
        return (
            <Stack className="document-container" verticalFill={true}>

                <Stack.Item styles={stackItemStyles} className='document-info'>
                    <Stack horizontal>
                        <Stack.Item grow styles={stackChildItemStyles}>{documentItem.id.split(':')[1]}</Stack.Item>
                        <Stack.Item
                            styles={stackChildItemStyles}>{t('DocumentDetails.sended')} {Moment(documentItem.createdAt).format('DD-MM-YYYY, HH:mm')}</Stack.Item>
                    </Stack>
                    <Stack horizontal wrap>
                        <Stack.Item styles={stackItemStyles} className='document-title'
                                    grow>{documentItem.title}</Stack.Item>
                        <Stack.Item className='top-sign-buttons' grow>
                            {(!props.drafts && !isInArchive) && <div className='sign-buttons'>
                                <DefaultButton iconProps={archiveIcon} onClick={Archive}
                                               text={t('FilterInfo.verticalMenuArchive')}/>
                            </div>}
                            {isInArchive && <div className='sign-buttons'>
                                <DefaultButton iconProps={archiveIcon} onClick={Unarchive}
                                               text={t('FilterInfo.verticalMenuUnArchive')}/>
                            </div>}
                            {actions.attributes ? actions.attributes.options.map((option: any, i: any) => {
                                if ((/WITHDRAWAL/i).test(option.id)) {
                                    return (
                                        <div className='sign-buttons' key={i}>
                                            <DefaultButton key={i} iconProps={withdrawalIcon}
                                                           onClick={() => sendAction(option.id)}
                                                           text={option.description}/>
                                        </div>
                                    );
                                }
                            }) : null}
                            {documentItem.files.map((file: any, i: any) => {
                                if (file.filePurpose === 'SIGNED_CONTENT_FILE') {
                                    return (
                                        <div className='sign-buttons' key={i}>
                                            <DefaultButton
                                                onClick={() => SaveToOneDrive(file.id, file.filename)}>{successSave ? t('Download.succes') : t('Download.savetoonedrive')}</DefaultButton>
                                        </div>
                                    );
                                }
                            })}

                        </Stack.Item>
                    </Stack>
                    {!props.drafts &&
                        <Stack className="document-progress" horizontal>
                            <Stack.Item>
                                <div className={'item-image' + ' ' + getStatus(documentItem.status)?.class}>
                                    <Image src={getStatus(documentItem.status)?.icon} imageFit={ImageFit.center}
                                           className={getStatus(documentItem.status)?.class}/>
                                </div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <Stack>
                                    <Stack.Item
                                        className='document-progress-text-top'><span>{getMessage(documentItem, t).message}</span></Stack.Item>
                                    <Stack.Item><Progressbar item={documentItem}/></Stack.Item>
                                    <Stack.Item className='document-progress-text-bottom'><span></span></Stack.Item>
                                </Stack>

                            </Stack.Item>
                        </Stack>
                    }
                </Stack.Item>
                <Stack.Item styles={pivotContainerStackItem} grow={8} disableShrink>

                    <Stack.Item styles={pivotStackItem}>
                        <Pivot aria-label="Pivot" className='document-pivot'>
                            <PivotItem alwaysRender={true}
                                       headerText={t('DocumentDetails.details')}
                                       headerButtonProps={{
                                           'data-order': 1,
                                       }}>
                                <DocumentDetails sender={sender} documentItem={documentItem}
                                                 recipients={recipients}></DocumentDetails>
                            </PivotItem>
                            <PivotItem headerText={t('DocumentDetails.detailsTabFilesTitle')} alwaysRender={true}>
                                <FileList files={documentItem.files} id={props.id} token={props.token}
                                          documentStatus={getStatus(documentItem.status)?.class}
                                          promptForConsent={props.promptForConsent} draft={props.drafts}></FileList>
                            </PivotItem>
                            {!props.drafts && <PivotItem headerText={t('DocumentDetails.detailsHistory')} alwaysRender={true}>
                                <History token={props.token} id={props.id} sender={sender}></History>
                            </PivotItem>}
                        </Pivot>
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles} className="document-sign-buttons" grow={1} disableShrink>
                    <Modal titleAriaId={titleId} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false}
                           containerClassName='document-modal'>
                        <div className='modal-header'>
                            <span>{t('DocumentDetails.PreviewDocument')}</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={hideModal}
                            />
                        </div>

                        <div className='modal-body'>
                            <Sign token={props.token} id={documentId}
                                  documentStatus={getStatus(documentItem.status)?.class}></Sign>
                        </div>

                    </Modal>

                    <Modal titleAriaId={titleSignId} isOpen={isignModalOpen} onDismiss={hideSignModal}
                           isBlocking={false}
                           containerClassName='document-modal'>
                        <div className='modal-header'>
                            <span>{t('DocumentDetails.buttonGoToSign')}</span>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={hideSignModal}
                            />
                        </div>
                        <div className='modal-body iframe'>
                            <iframe title='sign' id='frame' width="100%" height="100%" src={signUri}></iframe>

                        </div>

                    </Modal>

                    <Stack horizontal>
                        {!props.drafts && <Stack.Item grow><DefaultButton onClick={showModal}
                                                                          disabled={getStatus(documentItem.status)?.class === 'withdrawn' ? true : false}>{t('DocumentDetails.PreviewDocument')}</DefaultButton></Stack.Item>}
                        {!props.drafts && <Stack.Item grow><DefaultButton className='go-to-sign'
                                                                          onClick={() => sendAction(signatureId)}
                                                                          disabled={signActive}>{t('DocumentDetails.buttonGoToSign')}</DefaultButton></Stack.Item>}
                        {props.drafts && <Stack.Item grow><DefaultButton className='go-to-sign'
                                                                         onClick={() => getJwtRedirectToken(documentItem.id.split(':')[1])}>{t('DocumentDetails.GoBackToEdit')}</DefaultButton></Stack.Item>}
                    </Stack>
                </Stack.Item>
            </Stack>
        );
    } else {
        return (
            <Stack styles={stackStyles}><OtpHelper otpChallange={otpChallange} setGetNextChallenge={setGetNextChallenge}
                              documentId={props.id} step={otpStep} setotpStep={setotpStep}/></Stack>
        );
    }
}

export default DocumentPreview
